import axios from 'axios';
import { useEffect } from 'react'
import { useRecoilState } from 'recoil';
import { allStatiAtom, currencyAtom, igostatusAtom, paymentAtom, statisticsAtom } from './Atom';
import getSymbolFromCurrency from 'currency-symbol-map';


export const ApiCalls = () => {
    const [statistics, setStatistics] = useRecoilState(statisticsAtom)
    const [allStatistics, setAllStatistics] = useRecoilState(allStatiAtom)
    const [payements, setPayements] = useRecoilState(paymentAtom)
    const [currency, setCurrency] = useRecoilState(currencyAtom)
    const [igo_status, setIgostatus] = useRecoilState(igostatusAtom)


    useEffect(() => {

        const request1 = axios.get(`${process.env.REACT_APP_MAIN_API_URL}/api/razorpay/get-credentials`, { headers: { "Authorization": 'Bearer ' + localStorage.getItem('api_token') } })

        const request2 = axios.get(`${process.env.REACT_APP_MAIN_API_URL}/api/stripe/get-credentials`, { headers: { "Authorization": 'Bearer ' + localStorage.getItem('api_token') } })

        // Use axios.all to execute all requests in parallel and wait for all of them to complete
        axios.all([request1, request2])
            .then(axios.spread((...responses) => {

                // All requests are now complete, do something with the response data
                var razorpay = responses[0].data.data
                var stripe = responses[1].data.data
                var localPayement = { ...payements }
                if (razorpay.length !== 0) {
                    localPayement = { ...localPayement, razorpay: razorpay }
                }
                if (stripe.length !== 0) {
                    localPayement = { ...localPayement, stripe: stripe }
                }
                localPayement = { ...localPayement, return: true }
                setPayements(localPayement)
            }))

        var isMounted = true;

        axios.post(`${process.env.REACT_APP_MAIN_API_URL}/api/dashboard/get-connected-store-statistics`, {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') },
        })
            .then(async function (response) {



                var data = response.data.store_list
                setStatistics(data)

            })
            .catch(function (error: any) {
                console.log('error = ', error)
            })




        axios.post(`${process.env.REACT_APP_MAIN_API_URL}/api/igolite/igo-lite-get-credentials`, {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') },
        })
            .then(async function (response) {




                var data = response.data
                setIgostatus(data)

            })
            .catch(function (error: any) {
                console.log('error = ', error)
            })




        axios.get(`${process.env.REACT_APP_MAIN_API_URL}/api/dashboard/get-total-order-and-revenue-count-data`, {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') },
        })
            .then(async function (response) {
                var data = await response.data.data

                if (isMounted) {
                    if (data) {

                        setAllStatistics(data)
                    }
                }
                if (data === undefined) {
                    setAllStatistics({
                        total_revenue: '0',
                        total_orders: '0',
                        open_orders: '0',
                        completed_orders: '0'
                    })
                }
            })
            .catch(function (error: any) {
                console.log(error)
            })

        const currentCurrency: any = getSymbolFromCurrency('INR')
        setCurrency(currentCurrency)


        return () => { isMounted = false }





    }, [])

    return null

}
