import { atom } from "recoil";

export const statisticsAtom = atom({
    key: 'pluginStatistics',
    default: {
        woocommerce: { total_orders: '', total_revenue: '', open_orders: '', completed_orders: '', },
        shopify: { total_orders: '', total_revenue: '', open_orders: '', completed_orders: '', },
        amazon: { total_orders: '', total_revenue: '', open_orders: '', completed_orders: '', },
        flipkart: { total_orders: '', total_revenue: '', open_orders: '', completed_orders: '', },
        igo_lite: { total_orders: '', total_revenue: '', open_orders: '', completed_orders: '', },
        ondc: { total_orders: '', total_revenue: '', open_orders: '', completed_orders: '', },
        bigcommerce: { total_orders: '', total_revenue: '', open_orders: '', completed_orders: '', }
    }
})

export const allStatiAtom = atom({
    key: 'allStatistics',
    default: {
        total_revenue: '',
        total_orders: '',
        open_orders: '',
        completed_orders: ''
    }
})

export const paymentAtom = atom({
    key: 'payementsPlugins',
    default: {
        razorpay: false,
        stripe: false,
        return: false
    }
})

export const currencyAtom = atom({
    key: 'currencySymbol',
    default: ''
})

export const igostatusAtom = atom({
    key: 'igo_status',
    default:['']
})

