import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth/core/Auth'

import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { ThreeDots } from 'react-loader-spinner'
import { useRecoilValue } from 'recoil'
import { allStatiAtom, currencyAtom } from '../../../../app/modules/Atom'




const ListsWidget2: React.FC = () => {
  const statistics = useRecoilValue(allStatiAtom)
  const currency = useRecoilValue(currencyAtom)
  const loading1 = useRef<HTMLDivElement | null>(null)
  const card1 = useRef<HTMLDivElement | null>(null)
  const loading2 = useRef<HTMLDivElement | null>(null)
  const card2 = useRef<HTMLDivElement | null>(null)
  const loading3 = useRef<HTMLDivElement | null>(null)
  const card3 = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (statistics.open_orders !== '' || undefined || null || 0) {
      loading1.current?.classList.add('d-none')
      card1.current?.classList.remove('d-none')
      card1.current?.classList.add('d-flex')
      loading2.current?.classList.add('d-none')
      card2.current?.classList.remove('d-none')
      loading3.current?.classList.add('d-none')
      card3.current?.classList.remove('d-none')
    }

  }, [statistics])



  const { currentUser } = useAuth()


  return (
    <>
      <div className='card mb-5 mb-xl-10 mt-n4'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-125px symbol-fixed position-relative'>
                <img src={currentUser?.logo === null || "" ? toAbsoluteUrl('/media/logos/empty-user.jpg') : currentUser?.logo} alt='user-logo' style={{
                  borderRadius: '100px'
                }} />
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <h1 className='me-1'>{currentUser?.name}</h1>

                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <span className='d-flex align-items-center text-gray-400  me-5 mb-2'>
                      <KTSVG
                        path='/media/icons/duotune/communication/com011.svg'
                        className='svg-icon-4 me-1'
                      />
                      {currentUser?.email}
                    </span>
                    <span className='d-flex align-items-center text-gray-400  me-5 mb-2'>
                      <KTSVG
                        path='/media/icons/duotune/communication/com005.svg'
                        className='svg-icon-4 me-1'
                      />
                      {currentUser?.phone}
                    </span>
                    <span className='d-flex align-items-center text-gray-400  mb-2'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen022.svg'
                        className='svg-icon-4 me-1'
                      />
                      {currentUser?.category}
                    </span>
                  </div>
                </div>

                <div className='d-flex my-4'>
                  <Link
                    to='/plugins/add-plugins'
                    className='btn btn-sm btn-light me-2'
                    id='kt_user_follow_button'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr012.svg'
                      className='svg-icon-3 d-none'
                    />

                    <span className='indicator-label'>Connect Store</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </Link>
                  <Link to={'/products/addProduct-cards'} className='btn btn-sm btn-primary me-3'>
                    Add Products
                  </Link>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap mt-n3'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='fw-bold fs-6 text-gray-400 d-flex justify-content-center'>Earnings</div>
                      <div ref={loading1} className="d-flex justify-content-center">

                        <ThreeDots
                          height="50"
                          width="50"
                          radius="9"
                          color="#C8CDCF"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          visible={true}
                        />
                      </div>
                      <div ref={card1} className='d-none align-items-center'>
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr066.svg'
                          className='svg-icon-3 svg-icon-success me-2'
                        />
                        <div className='fs-2 fw-bolder'>{currency}{(parseFloat(statistics.total_revenue)).toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0}</div>
                      </div>

                    </div>

                    <div className='d-grid border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3' style={{ justifyItems: 'center' }}>
                      <div className='fw-bold fs-6 text-gray-400 '>Total Orders</div>
                      <div className=''>
                        <div ref={loading2} className="">
                          <ThreeDots
                            height="50"
                            width="50"
                            radius="9"
                            color="#C8CDCF"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            visible={true}
                          />
                        </div>
                        <div ref={card2} className='d-none fs-2 fw-bolder'>{statistics.total_orders || 0}</div>
                      </div>


                    </div>

                    <div className='d-grid border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3' style={{ justifyItems: 'center' }}>
                      <div className='fw-bold fs-6 text-gray-400'>Open Orders</div>
                      <div className=''>
                        <div ref={loading3} className="">
                          <ThreeDots
                            height="50"
                            width="50"
                            radius="9"
                            color="#C8CDCF"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            visible={true}
                          />
                        </div>
                        <div ref={card3} className='d-none fs-2 fw-bolder'>{statistics.open_orders || 0}</div>
                      </div>


                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { ListsWidget2 }
