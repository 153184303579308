import React, { useRef, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'
import { Col, Row } from 'react-bootstrap'
import axios from 'axios'

const initialValues = {
  email: 'admin@demo.com',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {

  let navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const submitBtn2 = useRef<HTMLButtonElement | null>(null)
  const submitBtn1 = useRef<HTMLButtonElement | null>(null)

  const [password, setPassword] = useState('')


  const [email, setEmail] = useState('')

  const [registrationotp, setregistrationotp] = useState<any>()

  const [maketrue, setMaketrue] = useState(false)
  const [ist, setist] = useState('')

  var integerNumber1 = parseInt(ist);

  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({ data: { result } }) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })


  const handleotp = async (e: any) => {

    e.preventDefault();

    submitBtn1.current?.setAttribute('data-kt-indicator', 'on')
    submitBtn1.current?.setAttribute('disabled', 'on')




    try {


      const response = await axios.post(`${process.env.REACT_APP_MAIN_API_URL}/api/auth/send-forgot-password-otp-mail`, { email: email });


      setregistrationotp(response.data.registration_otp)
      setMaketrue(true)


    } catch (error) {
      console.error(error); // Log any errors that occurred during the request
    }
    finally {

      submitBtn1.current?.removeAttribute('data-kt-indicator')
      submitBtn1.current?.removeAttribute('disabled')
    }


  };


  const handleSubmit = async (e: any) => {
    e.preventDefault();


    setLoading(true);


    var mergedNumber = parseInt(
      `${integerNumber1}`
    );



    if (mergedNumber === registrationotp) {


      try {



        submitBtn2.current?.setAttribute('data-kt-indicator', 'on')
        submitBtn2.current?.setAttribute('disabled', 'on')

        const response: any = await axios.post(`${process.env.REACT_APP_MAIN_API_URL}/api/auth/reset-password`, { email: email, password: password });


        if (response.data.statusCode === 200) {
          // Use the correct navigate function from your library, e.g., navigate('/login')
          navigate('/login'); // navigates to login page or change to dashboard page
        } else {
          alert('Please reload the page');
        }

      } catch (error: any) {
        console.log("eroor comes from", error.response.data);
        if (error.response.data.statusCode === 409) {

          alert('Account with this email already exists.')
        }

      } finally {
        // setLoading(false);
        submitBtn2.current?.removeAttribute('data-kt-indicator')
        submitBtn2.current?.removeAttribute('disabled')
      }
    } else {
      alert('Invalid OTP');
      return;
    }
  };

  return (
    <>

      <div className='w-lg-550px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>

        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Forgot Password ?</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please try again.
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>Sent password reset. Please check your email</div>
            </div>
          )}
          {/* end::Title */}

          {/* begin::Form group */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>
            <Row>

              <Col className='col-8'>
                <input
                  required
                  value={email}
                  placeholder='Email'
                  type='email'
                  name='email'
                  autoComplete='off'
                  onChange={(e) => setEmail(e.target.value)}
                  // {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid'

                  )} />

              </Col>
              <Col className='col-3'>
                <div className='text-center w-125px'>
                  <button type='submit' className='btn btn-bg-secondary ' style={{ width: "150px", marginLeft: "-8px" }} onClick={handleotp} ref={submitBtn1}>
                    <span className='indicator-label'>Generate otp</span>
                    <span className='indicator-progress'>
                      Please wait
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </button>

                </div>
              </Col>
            </Row>




          </div>

          {maketrue && (
            <><div className='fv-row mb-7'>
              <label className='form-label fw-bolder text-dark fs-6 required'>OTP</label>
              <input
                required

                placeholder='OTP'
                type="number"
                id='ist'
                maxLength={6}
                name='ist'
                value={ist}
                onChange={(e) => setist(e.target.value)}
                className={clsx(
                  'form-control form-control-lg form-control-solid'

                )} />

            </div><div className='fv-row mb-7'>
                <label className='form-label fw-bolder text-dark fs-6 required'>New Password</label>
                <input
                  required
                  value={password}
                  type='text'
                  placeholder='Password'
                  name='password'
                  autoComplete='off'
                  id="myInput2"
                  onChange={(e) => setPassword(e.target.value)}
                  className={clsx(
                    'form-control form-control-lg form-control-solid'

                  )} />

              </div></>
          )}
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>

            <button ref={submitBtn2} type='submit' className='btn btn-primary' onClick={handleSubmit} >
              <span className='indicator-label'>Submit</span>
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bolder ms-3'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Cancel
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </form>

      </div>

    </>
  )
}
