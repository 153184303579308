
import { useIntl } from 'react-intl'
import { MegaMenu } from './MegaMenu'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MenuItem } from './MenuItem'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
      <MenuInnerWithSub
        title='Products'
        to='/products'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        {/* PAGES */}

        <MenuItem
          to='/products/addProduct-cards'
          title='Add Products'
        />
        <MenuItem


          to='/products/my-products'
          title='My Products'
        />
        <MenuItem
          to='/products/global-catalogue'
          title='Global Catalogue'
        />

      </MenuInnerWithSub>

      <MenuInnerWithSub title='Orders' to='/orders' menuPlacement='bottom-start' menuTrigger='click'>
        {/* PAGES */}

        <MenuItem
          to='/orders/all-orders'
          title='All Orders'
        />

      </MenuInnerWithSub>

      <MenuItem

        to='/reports/order-reports'
        title='Reports'

      />


      <MenuItem

        to='/Ai-Tools/ai-website'

        title='Website With AI'


      />


      <MenuItem

        to='/Ai-Tools/ai-promotion'

        title='Promotions With AI'


      />


      <MenuInnerWithSub title='Plugins' to='/plugins' menuPlacement='bottom-start' menuTrigger='click'>


        {/* PAGES */}

        <MenuItem
          to='/plugins/add-plugins'
          title='Add plugins
          '
        />
        <MenuItem
          to='/plugins/my-plugins'
          title='My plugins
          '
        />

        <MenuItem to='/plugins/marketing-plugins' title='Marketing plugins' />
      </MenuInnerWithSub>


      <MenuInnerWithSub title='Settings' to='/settings' menuPlacement='bottom-start' menuTrigger='click'>
        {/* PAGES */}

        <MenuItem
          to='/settings/account-settings'
          title='Account Settings
          '
        />

        <MenuItem
          to='/settings/activity'
          title='Activities & Recharge
          '
        />
        <MenuItem
          to='/settings/support'
          title='Support
          '
        />
      </MenuInnerWithSub>
    </>
  )
}
