/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import axios from 'axios'
import { Bars } from 'react-loader-spinner'
import { capitalizeFirstLetter, timedRefresh } from '../../../../app/utils/Helper'
import { useRecoilValue } from 'recoil'
import { currencyAtom } from '../../../../app/modules/Atom'

type Props = {
  className: string
}

const ChartsWidget3: React.FC<Props> = ({ className }) => {


  // Sales Statistics page UI and Functionality


  const chartRef = useRef<HTMLDivElement | null>(null)
  const currency = useRecoilValue(currencyAtom)

  const [graphMonth, setGraphMonth] = useState([])
  const [graphMonthData, setGraphMonthData] = useState([])
  const [graphWeek, setGraphWeek] = useState([])
  const [graphweekData, setGraphWeekData] = useState([])
  const [graphHorizondal, setGraphHorizondal] = useState([]);

  const [graphVertical, setGraphVertical] = useState([]);

  const loading2 = useRef<HTMLDivElement | null>(null)
  const card2 = useRef<HTMLDivElement | null>(null)

  const [filter, setFilter] = useState('all')
  const [salesActive, setSalesActive] = useState('month')

  if (graphWeek.length !== 0 && graphMonth.length !== 0) {
    loading2.current?.classList.add('d-none')
    card2.current?.classList.remove('d-none')
  }




  useEffect(() => {


    if (filter === "all") {


      const allWeek = axios.get(`${process.env.REACT_APP_MAIN_API_URL}/api/dashboard/get-weekly-order-revenue-graph-data`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') },
      })

      const allMonth = axios.get(`${process.env.REACT_APP_MAIN_API_URL}/api/dashboard/get-monthly-order-revenue-graph-data`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') },
      })

      axios.all([allWeek, allMonth])
        .then(axios.spread((...responses) => {


          var week = responses[0].data
          var month = responses[1].data


          if (week) {
            var week1: any = (Object.keys(week));
            setGraphWeek(week1)

            var week2: any = (Object.values(week));

            const roundedNumbers = week2.map((num: number) => {
              num = Math.round(num)
              return num
            });
            setGraphWeekData(roundedNumbers)
          }

          if (month) {
            var month1: any = (Object.keys(month));
            setGraphMonth(month1)

            var month2: any = (Object.values(month));

            const roundedNumbers = month2.map((num: number) => {
              num = Math.round(num)
              return num
            });
            setGraphMonthData(roundedNumbers)
          }
        }))
        .catch(function (error: any) {
          console.log(error.response.data)
          if (error.response.data === 'Verification error.') {

            timedRefresh()
          }
        }
        )

    } else {
      const indiWeek = axios.post(`${process.env.REACT_APP_MAIN_API_URL}/api/dashboard/get-individual-plugin-weekly-order-revenue-graph-data`, { plugin_name: filter }, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') },
      })

      const indiMonth = axios.post(`${process.env.REACT_APP_MAIN_API_URL}/api/dashboard/get-individual-plugin-monthly-order-revenue-graph-data`, { plugin_name: filter }, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') },
      })

      axios.all([indiWeek, indiMonth])
        .then(axios.spread((...responses) => {
          var week = responses[0].data
          var month = responses[1].data


          if (week) {
            var week1: any = (Object.keys(week));
            setGraphWeek(week1)

            var week2: any = (Object.values(week));

            const roundedNumbers = week2.map((num: number) => {
              num = Math.round(num)
              return num
            });
            setGraphWeekData(roundedNumbers)
          }

          if (month) {
            var month1: any = (Object.keys(month));
            setGraphMonth(month1)

            var month2: any = (Object.values(month));

            const roundedNumbers = month2.map((num: number) => {
              num = Math.round(num)
              return num
            });
            setGraphMonthData(roundedNumbers)
          }
        }))
        .catch(function (error: any) {
          console.log(error.response.data)
          if (error.response.data === 'Verification error.') {

            timedRefresh()
          }
        }
        )
    }


  }, [filter])

  useEffect(() => {

    handleGraphData(salesActive)

  }, [graphweekData, graphMonthData])


  const handleGraphData = (id: string) => {

    setSalesActive(id)

    if (id === "month") {
      setGraphHorizondal(graphMonth)
      setGraphVertical(graphMonthData)


    } else {
      setGraphHorizondal(graphWeek)
      setGraphVertical(graphweekData)
    }
  }

  useEffect(() => {



    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, graphHorizondal, graphVertical, currency))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, graphHorizondal, graphVertical, graphMonthData, graphweekData])




  return (
    <>
      <div ref={loading2} className={`card d-block ${className}`} style={{ height: '524px' }}>


        <div className='d-flex justify-content-center align-items-center '
          style={{
            width: '-webkit-fill-available',
            height: '-webkit-fill-available',
            position: 'absolute'
          }}>
          <Bars
            height="80"
            width="80"
            color="#C8CDCF"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      </div>

      <div
        ref={card2}
        className={`card d-none ${className}`}
        style={{
          height: '522px',
        }}
      >
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column mb-6'>
            <span className='mb-1 mt-3 fw-bolder fs-2'>Sales Statistics</span>
            <span className='mt-1 text-gray-400 fw-bold fs-6'>Cumulative Sales Statistics Across Platforms</span>
          </h3>


          <div className='card-toolbar'>
            <ul className='nav'>
              <li className='nav-item'>
                <a
                  className={'nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 sales-toggle ' + (salesActive === 'month' ? 'active' : '')}
                  id='month'
                  onClick={(e: any) => { handleGraphData(e.target.id) }}
                >
                  Month
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={'nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 sales-toggle ' + (salesActive === 'week' ? 'active' : '')}
                  id='week'

                  onClick={(e: any) => { handleGraphData(e.target.id) }}
                >
                  Week
                </a>
              </li>

            </ul>

            <button
              type='button'
              className='btn-sm form-select form-select-solid me-4 w-150px'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom'
              data-kt-menu-flip='top'
            >
              {filter === 'all' ? 'All Orders' : capitalizeFirstLetter(filter)}

            </button>
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-150px'
              data-kt-menu='true'
            >

              <div className='separator mb-3 opacity-75'></div>
              <div className='menu-item px-3'>
                <li
                  className='menu-link px-3'
                  onClick={() => {
                    setFilter('all')
                  }}
                >
                  All Orders
                </li>
              </div>
              <div className='menu-item px-3'>
                <li
                  className='menu-link px-3'
                  onClick={() => {
                    setFilter('woocommerce')
                  }}
                >
                  Woocommerce
                </li>
              </div>
              <div className='menu-item px-3'>
                <li
                  className='menu-link px-3'
                  onClick={() => {
                    setFilter('shopify')
                  }}
                >
                  Shopify
                </li>
              </div>
              <div className='menu-item px-3'>
                <li
                  className='menu-link px-3'
                  onClick={() => {
                    setFilter('amazon')
                  }}
                >
                  Amazon
                </li>
              </div>
              <div className='menu-item px-3'>
                <li
                  className='menu-link px-3'
                  onClick={() => {
                    setFilter('flipkart')
                  }}
                >
                  Flipkart
                </li>
              </div>
              <div className='menu-item px-3'>
                <li
                  className='menu-link px-3'
                  onClick={() => {
                    setFilter('bigcommerce')
                  }}

                >
                  Bigcommerce
                </li>
              </div>
            </div>
          </div>

          {/* end::Toolbar */}

        </div>

        <div className='card-body mt-'>

          {/* begin::Chart */}

          <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '350px' }}>

          </div>
        </div>

        {/* end::Body */}

      </div>

    </>
  )
}

export { ChartsWidget3 }

function getChartOptions(height: number, monthsData: string[], chartData: number[], currency: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-light-info')

  return {
    series: [
      {
        name: 'Revenue',
        data: chartData,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 412,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: monthsData,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return `${currency} ` + val;
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}