/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { WooOrderSales } from './content/WooOrderSales'
import { Link } from 'react-router-dom'
import { Bars } from 'react-loader-spinner'
import { timedRefresh } from '../../../../app/utils/Helper'

type Props = {
  className: string
}

const TablesWidget12: React.FC<Props> = ({ className }) => {
  const [orders, setOrders] = useState<any>([])

  const loading = useRef<HTMLDivElement | null>(null)
  const card = useRef<HTMLDivElement | null>(null)

  useEffect(() => {

    axios.post(`${process.env.REACT_APP_MAIN_API_URL}/api/common/get-order-list`, {
      plugin_name: 'all',
      offset: 0
    }, { headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') } })
      .then(function (response) {

        const data = response.data.data


        setOrders(data.slice(0, 5));
        loading.current?.classList.add('d-none')
        card.current?.classList.remove('d-none')

      })
      .catch(function (error: any) {
        console.log(error.response.data)
        if (error.response.data === 'Verification error.') {

          timedRefresh()
        }
      }

      )
  }, [])

  return (
    <div className='card mb-6 h-550px'>
      <div className='card-header'>
        <div className='card-title'>
          <h2 className='m-0 mt-n2 fs-2'>Orders Tracking</h2>
          <span className='mt-13 text-gray-400 fw-bold fs-6 ' style={{ position: 'absolute' }}>Recent Orders </span>
        </div>

        <div className='card-toolbar'>
          <Link to='/orders/all-orders' className='btn btn-sm btn-primary my-1'>
            See All Orders
          </Link>
        </div>

      </div>
      <div ref={loading} className='d-flex justify-content-center align-items-center'
        style={{
          width: '-webkit-fill-available',
          height: '-webkit-fill-available',
          position: 'absolute'
        }}>
        <Bars
          height="80"
          width="80"
          color="#C8CDCF"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
      {orders === '' ? <div ref={card} className='d-none' style={{
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <div className='card border-0 pt-3' style={{
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: "200px"
        }}>
          <>
            <div className='text-dark fw-bolder  fs-3 text-truncate'>No orders</div>
            <div className='text-gray fw-bold fs-6 text-truncate'>Connect plugin <Link to={'/plugins/platform'} className='text-primary-100 fw-bold text-hover-primary' style={{ color: '#009ef7ab' }}>
              here
            </Link></div>
          </>
        </div>
      </div>
        :
        <div ref={card} className='card-body pt-5 d-none'>
          <div className='card-body p-0'>
            <div className='table-responsive'>
              <table
                className='table table-flush align-middle table-row-bordered table-row-solid gy-4'
                id='kt_security_license_usage_table'
              >
                <thead className='border-gray-200 px-4 fs-5 fw-bolder bg-lighten'>
                  <tr>
                    <th className='text-center'>Platform</th>
                    <th className='text-center'>Order ID</th>
                    <th className='text-center'>Order Date</th>
                    <th className='text-center'>Customer Name</th>

                    <th className='text-center'>Status</th>
                  </tr>
                </thead>

                <tbody className='align-middle fs-6 px-5 text-gray-700'>

                  {orders.map((data: any, i: any) => {
                    return (
                      <WooOrderSales
                        key={i}
                        plugin={data.plugin_name}
                        orderid={data.orderid}
                        date={data.date_created}
                        firstname={data.shipping_first_name || ''}
                        lastname={data.shipping_last_name || ''}

                        status={data.status || ''}
                      />
                    )
                  })}



                </tbody>
              </table>
            </div>
          </div>
        </div>}




    </div>

  )
}

export { TablesWidget12 }