import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { register } from '../core/_requests'
import axios from 'axios'



import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
export function Registration() {


  useEffect(() => {
    return () => {
      setName('')
      setEmail('')
      setPhone('')
      setPassword('')
      setCategory('')
      setGdrivelink('')
      setAcceptTerms(false)
      setPasswordConfirmation('')
    }
  }, [])

  // const [loading, setLoading] = useState(false)
  let navigate = useNavigate()


  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [category, setCategory] = useState('')
  const [gdrivelink, setGdrivelink] = useState('')
  const [acceptTerms, setAcceptTerms] = useState(false)

  const [passIndicator, setPassIndicator] = useState(false)
  const submitBtn2 = useRef<HTMLButtonElement | null>(null)
  const submitBtn1 = useRef<HTMLButtonElement | null>(null)
  const [ist, setist] = useState('')
  const [registrationotp, setregistrationotp] = useState<any>()

  const [maketrue, setMaketrue] = useState(false)
  var integerNumber1 = parseInt(ist);

  const loading1 = useRef<HTMLDivElement | null>(null)
  const card = useRef<HTMLDivElement | null>(null)


  const handleotp = async (e: any) => {

    e.preventDefault();

    submitBtn1.current?.setAttribute('data-kt-indicator', 'on')
    submitBtn1.current?.setAttribute('disabled', 'on')



    loading1.current?.classList.remove('d-none')
    card.current?.classList.add('d-none')
    try {


      const response = await axios.post(`${process.env.REACT_APP_MAIN_API_URL}/api/auth/send-registration-otp-mail`, { email: email });
      // Assuming the API response contains data you want to log

      setregistrationotp(response.data.registration_otp)
      setMaketrue(true)
      loading1.current?.classList.add('d-none')
      card.current?.classList.remove('d-none')

    } catch (error) {
      console.error(error); // Log any errors that occurred during the request
    }
    finally {

      submitBtn1.current?.removeAttribute('data-kt-indicator')
      submitBtn1.current?.removeAttribute('disabled')
    }


  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();



    setPassIndicator(true);

    var mergedNumber = parseInt(
      `${integerNumber1}`
    );

    // Check if registrationotp is defined and accessible

    if (mergedNumber === registrationotp) {
      var gdrive = gdrivelink || 'null'; // Use default value 'null' if gdrivelink is an empty string

      try {

        if (password !== passwordConfirmation) {
          alert('Password and confirm password mismatch.')
          return
        }
        if (!acceptTerms) {
          alert('Please accept terms and conditions')
          return
        }
        if (!/\d/.test(password) || password.length < 8) {
          alert('Password Should Contain Atleast 8 characters & 1 Number')
          return
        }



        submitBtn2.current?.setAttribute('data-kt-indicator', 'on')
        submitBtn2.current?.setAttribute('disabled', 'on')

        const { data: auth } = await register(name, email, phone, password, category, gdrive);
        if (auth.statusCode === 200 || auth.statusCode === 201) {
          // Use the correct navigate function from your library, e.g., navigate('/login')
          navigate('/login'); // navigates to login page or change to dashboard page
        } else {
          alert('Please reload the page and re-register');
        }
      } catch (error: any) {
        console.log("eroor comes from", error.response.data);
        if (error.response.data.statusCode === 409) {

          alert('Account with this email already exists.')
        }

      } finally {
        // setLoading(false);
        submitBtn2.current?.removeAttribute('data-kt-indicator')
        submitBtn2.current?.removeAttribute('disabled')
      }
    } else {
      alert('Invalid OTP');
      return;
    }
  };


  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  // function myFunction() {
  //   var x: any = document.getElementById("myInput");
  //   if (x.type === "password") {
  //     x.type = "text";
  //   } else {
  //     x.type = "password";
  //   }
  // }

  // function myFunction2() {
  //   var x: any = document.getElementById("myInput2");
  //   if (x.type === "password") {
  //     x.type = "text";
  //   } else {
  //     x.type = "password";
  //   }
  // }

  return (



    <>

      <div className='w-lg-550px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>

        <div
          className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework`}

          id='kt_login_signup_form'
        >
          <div className='mb-10 text-center'>
            <h1 className='text-dark mb-3'>Create an Account</h1>
          </div>

          {/* begin::Form group Firstname */}
          <div className='row fv-row  mb-5 mt-md-4'>
            <div className='col-xl-12'>
              {/* begin::Form group Lastname */}
              <div className='fv-row mb-2'>
                <label className='form-label fw-bolder mb-2 text-dark fs-6 required'>Store name</label>
                <input
                  required
                  value={name}
                  name='lastname'
                  onChange={(e) => setName(e.target.value)}
                  placeholder='Store name'
                  type='text'
                  autoComplete='off'
                  // {...formik.getFieldProps('lastname')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid'

                  )} />

              </div>

            </div>
          </div>



          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>
            <Row>

              <Col className='col-8'>
                <input
                  required
                  value={email}
                  placeholder='Email'
                  type='email'
                  name='email'
                  autoComplete='off'
                  onChange={(e) => setEmail(e.target.value)}
                  className={clsx(
                    'form-control form-control-lg form-control-solid'

                  )} />

              </Col>
              <Col className='col-3'>
                <div className='text-center w-125px'>
                  <button type='submit' className='btn btn-bg-secondary ' style={{ width: "150px", marginLeft: "-8px" }} onClick={handleotp} ref={submitBtn1}>
                    <span className='indicator-label'>Generate otp</span>
                    <span className='indicator-progress'>
                      Please wait
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </button>

                </div>
              </Col>
            </Row>




          </div>

          {maketrue && (
            <div className='fv-row mb-7'>
              <label className='form-label fw-bolder text-dark fs-6 required'>OTP</label>
              <input
                required

                placeholder='OTP'
                type="number"
                id='ist'
                maxLength={6}
                name='ist'
                value={ist}
                onChange={(e) => setist(e.target.value)}
                className={clsx(
                  'form-control form-control-lg form-control-solid'

                )} />

            </div>
          )}

          <div className='row fv-row  mb-5 mt-md-4'>
            <div className='col-xl-12'>

              <div className='fv-row mb-2'>
                <label className='form-label fw-bolder mb-2 text-dark fs-6 required'>Phone Number</label>
                <input
                  required
                  value={phone}
                  name='lastname'
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder='Phone Number'
                  type='number'
                  autoComplete='off'

                  className={clsx(
                    'form-control form-control-lg form-control-solid'

                  )} />

              </div>

            </div>
          </div>


          <div className='mb-7 fv-row' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6 required'>Password</label>
              <div className='position-relative mb-3'>
                <input
                  required
                  value={password}
                  type='password'
                  placeholder='Password'
                  name='password'
                  autoComplete='off'
                  id="myInput2"
                  onChange={(e) => setPassword(e.target.value)}

                  className={clsx(
                    'form-control form-control-lg form-control-solid'

                  )} />
                <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                  data-kt-password-meter-control="visibility">
                  <i className="bi bi-eye-slash fs-2"></i>

                  <i className="bi bi-eye fs-2 d-none"></i>
                </span>

              </div>
              {/* begin::Meter */}
              <div
                className='d-flex align-items-center mb-3'
                data-kt-password-meter-control='highlight'
              >
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
              </div>
              {/* end::Meter */}
            </div>
            <div className={passIndicator && (!/\d/.test(password) || password.length < 8) ? 'text-danger' : 'text-muted'}>
              {passIndicator && !/\d/.test(password) ? 'Password Should Contain Atleast 1 Number' : passIndicator && password.length < 8 ? 'Password Should Contain Atleast 8 characters' : 'Use 8 or more characters with a mix of letters, numbers & symbols.'}
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group Confirm password */}
          <div className='mb-7 fv-row' data-kt-password-meter='true'>
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6 required'>Confirm Password</label>
              <div className='position-relative mb-3'>
                <input
                  required
                  value={passwordConfirmation}
                  type='password'
                  name='passwordConfirmation'
                  placeholder='Password confirmation'
                  autoComplete='off'
                  id="myInput"
                  onChange={(e) => setPasswordConfirmation(e.target.value)}

                  className={clsx(
                    'form-control form-control-lg form-control-solid'

                  )} />
                <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                  data-kt-password-meter-control="visibility">
                  <i className="bi bi-eye-slash fs-2"></i>

                  <i className="bi bi-eye fs-2 d-none"></i>
                </span>
              </div>
            </div>

            {passwordConfirmation && (password !== passwordConfirmation) && <div className='text-danger'>
              Both Password & Confirm Password Should be same
            </div>}
          </div>
          {/* end::Form group */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Category</label>
            <input
              required
              placeholder='Category'
              type='text'
              autoComplete='off'
              name='category'
              value={category}
              onChange={(e) => setCategory(e.target.value)}

              className={clsx(
                'form-control form-control-lg form-control-solid'

              )} />

          </div>
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>GDrive Link</label>
            <input
              placeholder='GDrivelink'
              type='text'
              value={gdrivelink}
              onChange={(e) => setGdrivelink(e.target.value)}
              autoComplete='off'
              name='gdrivelink'

              className={clsx(
                'form-control form-control-lg form-control-solid'

              )} />

          </div>

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id='kt_login_toc_agree'
                onChange={(e) => setAcceptTerms(!acceptTerms)}
                name='acceptTerms'
                defaultChecked={acceptTerms} />
              <div className='ps-2'>I Agree the
              <a
  className='form-check-label fw-bolder text-gray-700 fs-6 text-hover-primary'
  href='https://isgoing.online/tnc.php'
  target="_blank"
  rel="noreferrer"
>
                  terms and conditions.
                </a>
              </div>

            </div>
          </div>



          <div className='text-center'>
            <button ref={submitBtn2} type='submit' className='btn btn-primary' onClick={handleSubmit} >
              <span className='indicator-label'>Sign Up</span>
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>

          </div>

          <div className='text-gray-400 fw-bold fs-4 text-center  mt-4'>
            Already have an account? <br></br>
            <Link to='/auth/login' className='link-primary fw-bolder mt-4 '>
              Sign In
            </Link>
          </div>


        </div>


      </div>







    </>


  )
}