
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useAuth } from '../../../../app/modules/auth'
import { KTSVG } from '../../../helpers'
import { allStatiAtom, paymentAtom, statisticsAtom } from '../../../../app/modules/Atom'
import { useResetRecoilState } from 'recoil'
import {  Modal } from 'react-bootstrap'


export function AsideMenuMain() {
  const intl = useIntl()
  const { logout } = useAuth()
  const Swal = require('sweetalert2')

  const resetStatistics = useResetRecoilState(statisticsAtom)
  const resetAllStati = useResetRecoilState(allStatiAtom)
  const resetPayment = useResetRecoilState(paymentAtom)
  


  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleClose = () => setShowLogoutModal(false);
  const handleShow = () => setShowLogoutModal(true);

  const signout1 = () => {
    handleShow();
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };


  return (
    <>


    {/* assigning paths to different portal pages */}

      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art011.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItemWithSub
        to='/products'
        title='Products'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/art/art015.svg'
      >
        <AsideMenuItem to='/products/addProduct-cards' title='Add Products' hasBullet={true} />
        <AsideMenuItem to='/products/my-products/allproduct' title='My Products' hasBullet={true} />
        <AsideMenuItem to='/products/global-catalogue' title='Global Catalogue' hasBullet={true} />

      </AsideMenuItemWithSub>


      <AsideMenuItemWithSub
        to='/orders'
        title='Orders'
        icon='/media/icons/duotune/art/art012.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/orders/all-orders' title='My Orders' hasBullet={true} />

      </AsideMenuItemWithSub>



      <AsideMenuItem
        to='/reports/order-reports'
        title='Reports'
        icon='/media/icons/duotune/art/art019.svg'
        fontIcon='bi-person'
      />

      <AsideMenuItemWithSub
        to='/image_tools'
        title='Image Editing Tools'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/art/art020.svg'
      >
        <AsideMenuItem to='/image_tools/bg_remove' title='Background Remove' hasBullet={true} />

      </AsideMenuItemWithSub>


      <AsideMenuItemWithSub
        to='/plugins'
        title='Plugins'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/art/art016.svg'
      >

        <AsideMenuItem to='/plugins/add-plugins' title='Add Plugins' hasBullet={true} />
        <AsideMenuItem to='/plugins/my-plugins' title='My plugins' hasBullet={true} />
        <AsideMenuItem to='/plugins/marketing-plugins' title='Marketing plugins' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* <AsideMenuItem
        to='/Ai-Tools/ai-website'
        icon='/media/icons/duotune/abstract/abs013.svg'
        title='Website With AI'
        fontIcon='new'
      />

      <AsideMenuItem
        to='/Ai-Tools/ai-promotion'
        icon='/media/icons/duotune/art/stars.svg'
        title='Promotions With AI'
        fontIcon='new'
      /> */}

      <AsideMenuItemWithSub
       to='/Ai-Tools'
       title='AI Creatives'
       fontIcon='new'
       icon='/media/icons/duotune/art/stars.svg'
      >
        <AsideMenuItem  to='/Ai-Tools/ai-promotion' title='Promotions Creatives' hasBullet={true}/>
        <AsideMenuItem  to='/Ai-Tools/ai-3dgen' title='3D Creatives' hasBullet={true}/>

      </AsideMenuItemWithSub>


      <AsideMenuItemWithSub
        to='/settings'
        title='Settings'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/art/art013.svg'
      >
        <AsideMenuItem to='/settings/account-settings' title='Account Settings' hasBullet={true} />
        <AsideMenuItem to='/settings/activity' title='Activities & Recharge' hasBullet={true} />
        <AsideMenuItem to='/settings/support' title='Support' hasBullet={true} />

      </AsideMenuItemWithSub>


      <div className='ms-0 menu-item '>
        <span className=' menu-link' onClick={signout1}>
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/art/art014.svg' className='svg-icon-2' />

            <span className='ms-n1 svg-icon svg-icon-2'>
              <span id="kt_docs_sweetalert_basic" className='menu-title px-5'>Logout</span>
            </span>
          </span>
        </span>
      </div>


      <Modal show={showLogoutModal} onHide={handleClose} centered dialogClassName="w-325px">


        <div className="modal-content" >

          <div className="modal-body" style={{ textAlign: 'center', }}>
            <img src={`${process.env.REACT_APP_IMAGE_API_URL}/portal_images/a_logout_3.jpg`} alt="Logout" style={{ width: '100px', height: '100px' }} />
            <p><strong>Are you sure you want to logout?</strong></p>
          </div>
          <div className="modal-footer border-0 mt-n9" style={{ justifyContent: "center" }}>

            <button type="button" className="btn btn-primary w-100px" onClick={handleLogout}>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger w-100px"

              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </div>

      </Modal>


    </>
  )
}
