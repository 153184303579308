import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import axios from 'axios'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import { Bars } from 'react-loader-spinner'
import { timedRefresh } from '../../../../app/utils/Helper'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}
type TrendProps = {
  keyword: any
  startTime: string
  endTime: string
  geo: string
  timezone: string
  property: string
  category: string
}

const MixedWidget10: React.FC<Props> = ({ className }) => {


  const { currentUser } = useAuth()

  const category = currentUser?.category

  const loading1 = useRef<HTMLDivElement | null>(null)
  const card1 = useRef<HTMLDivElement | null>(null)
  const submitBtn = useRef<HTMLButtonElement | null>(null)
  const [graphHorizondal, setGraphHorizondal] = useState([]);
  const [graphVertical, setGraphVertical] = useState([]);


  const [trendDetails, setTrendDetails] = useState<TrendProps>({
    keyword: category,
    startTime: '2023',
    endTime: '2023',
    geo: 'IN',
    timezone: '',
    property: '',
    category: '0',
  })
  const onChange = (event: any) => {
    if (event.target.id === "year-select") {
      switch (event.target.value) {
        case "2018":
          setTrendDetails((prevState) => ({ ...prevState, startTime: "2018" }))
          setTrendDetails((prevState) => ({ ...prevState, endTime: "2018" }))
          break;
        case "2019":
          setTrendDetails((prevState) => ({ ...prevState, startTime: "2019" }))
          setTrendDetails((prevState) => ({ ...prevState, endTime: "2019" }))
          break;
        case "2020":
          setTrendDetails((prevState) => ({ ...prevState, startTime: "2020" }))
          setTrendDetails((prevState) => ({ ...prevState, endTime: "2020" }))
          break;
        case "2021":
          setTrendDetails((prevState) => ({ ...prevState, startTime: "2021" }))
          setTrendDetails((prevState) => ({ ...prevState, endTime: "2021" }))
          break;
        case "2022":
          setTrendDetails((prevState) => ({ ...prevState, startTime: "2022" }))
          setTrendDetails((prevState) => ({ ...prevState, endTime: "2022" }))
          break;
        case "2023":
          setTrendDetails((prevState) => ({ ...prevState, startTime: "2023" }))
          setTrendDetails((prevState) => ({ ...prevState, endTime: "2023" }))
          break;
      }
      return;
    }
    setTrendDetails((prevState) => ({ ...prevState, [event.target.name]: event.target.value }))
  }




  if (graphHorizondal.length !== 0) {
    loading1.current?.classList.add('d-none')
    card1.current?.classList.remove('d-none')
  }

  useEffect(() => {

    const data = {
      keyword: trendDetails.keyword,
      startTime: trendDetails.startTime,
      endTime: trendDetails.endTime,
      geo: trendDetails.geo,
      timezone: trendDetails.timezone,
      property: trendDetails.property,
      category: trendDetails.category,
    }

    axios.post(`${process.env.REACT_APP_MAIN_API_URL}/api/googletrends/interestOverTime`, data, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') },
    })
      .then(async function (response) {


        var data1 = response.data.x_axis
        if (data1) {
          var data_1: any = (Object.values(data1));
          setGraphHorizondal(data_1)
        }

        var data2 = response.data.y_axis

        if (data2) {
          var data_2: any = (Object.values(data2));
          setGraphVertical(data_2)
        }

      })
      .catch(function (error: any) {


        if (error.response.data === 'Verification error.') {

          timedRefresh()
        }
      }

      )

  }, [trendDetails.category,
    trendDetails.endTime,
    trendDetails.geo,
    trendDetails.keyword,
    trendDetails.property,
    trendDetails.startTime,
    trendDetails.timezone])



  const handleSubmit = async (e: any) => {
    e.preventDefault()
    submitBtn.current?.setAttribute('data-kt-indicator', 'on')
    submitBtn.current?.setAttribute('disabled', 'on')
    var arr = []
    arr.push(trendDetails.keyword)

    const data = {
      keyword: arr,
      startTime: trendDetails.startTime,
      endTime: trendDetails.endTime,
      geo: trendDetails.geo,
      timezone: trendDetails.timezone,
      property: trendDetails.property,
      category: trendDetails.category,
    }


    await axios
      .post(`${process.env.REACT_APP_MAIN_API_URL}/api/googletrends/interestOverTime`, data, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') },
      })
      .then((response) => {
        var data1 = response.data.x_axis
        if (data1) {
          var data_1: any = (Object.values(data1));
          setGraphHorizondal(data_1)
        }

        var data2 = response.data.y_axis

        if (data2) {
          var data_2: any = (Object.values(data2));
          setGraphVertical(data_2)
        }

      })
      .catch(function (error: any) {
        console.log(error.response.data)
        if (error.response.data === 'Verification error.') {

          timedRefresh()
        }
      }

      )
      .finally(() => {
        submitBtn.current?.removeAttribute('data-kt-indicator')
        submitBtn.current?.removeAttribute('disabled')
      })
  }

  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, graphHorizondal, graphVertical))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, graphHorizondal, graphVertical])

  return (

    <><div ref={loading1} className={`card d-block ${className}`} style={{ height: '523px' }}>


      <div className='d-flex justify-content-center align-items-center '
        style={{
          width: '-webkit-fill-available',
          height: '-webkit-fill-available',
          position: 'absolute'
        }}>
        <Bars
          height="80"
          width="80"
          color="#C8CDCF"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true} />
      </div>
      {/* end::Body */}
    </div>

      <div ref={card1} className={`card d-none h-510px ${className}`}>
        {/* begin::Body */}
        <div className='card-body d-flex flex-column p-0'>
          {/* begin::Stats */}
          <div className='flex-grow-1 card-p pb-0'>
            <div className=' flex-stack flex-wrap '>
              <div className='me-2 '>
                <a className='text-dark text-hover-primary fw-bolder fs-3'>
                  Trends
                </a>

                <div className='text-muted fs-7 fw-bold'>Latest Trends</div>
              </div>

              <div className='card-toolbar'>

                <div className='mt-5'>
                  <form onSubmit={handleSubmit}>



                    <div className='row g-2'>




                      <div className="col-md">
                        <div className="form-floating">
                          <div className='mb-10'>
                            <label className='form-label fw-bold'>Category:</label>

                            <div>
                              <select
                                className='form-select form-select-solid'
                                data-kt-select2='true'
                                data-placeholder='Select option'
                                data-allow-clear='true'
                                defaultValue={'1'}
                                onChange={onChange}
                                name='category'
                                style={{ backgroundColor: "#f5f8fa" }}
                              >

                                <option value='0'>All categories</option>
                                <option value='71'>Food</option>
                                <option value='270'>Home Décor</option>
                                <option value='121'>Groceries</option>
                                <option value='234'>Cosmetics</option>
                                <option value='993'>Garments</option>
                                <option value='98'>Fashion accessories</option>


                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <div className='mb-10'>
                            <label className='form-label fw-bold'>Region:</label>

                            <div>
                              <select
                                className='form-select form-select-solid'
                                data-kt-select2='true'
                                data-placeholder='Select option'
                                data-allow-clear='true'
                                defaultValue={'1'}
                                onChange={onChange}
                                name='geo'
                                style={{ backgroundColor: "#f5f8fa" }}
                              >
                                <option value='IN'>India</option>

                                <option value='USA'>USA</option>
                                <option value='Canada'>Canada</option>
                                <option value='Europe'>Europe</option>
                                <option value='SEA'>South East Asia</option>


                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="form-floating">
                          <div className='mb-10'>
                            <label className='form-label fw-bold'>Year:</label>
                            <div>
                              <select
                                className='form-select form-select-solid'
                                data-kt-select2='true'
                                data-placeholder='Select option'
                                data-allow-clear='true'
                                defaultValue={'1'}
                                onChange={onChange}
                                id="year-select"
                                style={{ backgroundColor: "#f5f8fa" }}
                              >

                                <option value='2023'>2023</option>
                                <option value='2022'>2022</option>
                                <option value='2021'>2021</option>
                                <option value='2020'>2020</option>
                                <option value='2019'>2019</option>
                                <option value='2018'>2018</option>

                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="form-floating">
                          <div className='mb-10'>
                            <label className='form-label fw-bold'>Keyword:</label>

                            <div>
                              <input
                                type='text'
                                onChange={onChange}
                                className='form-control form-control-solid'
                                placeholder={"Enter keyword"}
                                value={trendDetails.keyword}
                                name='keyword'
                                style={{ backgroundColor: "#f5f8fa" }}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto mt-11">
                        <div className="form-floating">
                          <div className='mb-10'>
                            <button
                              className='btn btn-sm btn-primary'
                              ref={submitBtn}

                            >
                              <span className='indicator-label'>Apply</span>
                              <span className='indicator-progress'>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>


                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>


          {/* begin::Chart */}
          <div ref={chartRef} className='mixed-widget-7-chart card-rounded-bottom mt-n10'></div>
          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div></>
  )
}

export { MixedWidget10 }

function getChartOptions(height: number, trendsData: string[], chartData: number[]): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const lightColor = getCSSVariableValue('--bs-light-primary')

  return {
    series: [
      {
        name: 'Popularity',
        data: chartData,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {

      categories: trendsData,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',

        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ''
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}