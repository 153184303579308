/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
// import {Link} from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
// import {Languages} from './Languages'
import { toAbsoluteUrl } from '../../../helpers'
import { useResetRecoilState } from 'recoil'
import { allStatiAtom, paymentAtom, statisticsAtom } from '../../../../app/modules/Atom'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth()
  const Swal = require('sweetalert2')
  // const [statistics, setStatistics] = useRecoilState(statisticsAtom)
  // const [allStatistics, setAllStatistics] = useRecoilState(allStatiAtom)
  // const [payement, setPayement] = useRecoilState(paymentAtom)
  const resetStatistics = useResetRecoilState(statisticsAtom)
  const resetAllStati = useResetRecoilState(allStatiAtom)
  const resetPayment = useResetRecoilState(paymentAtom)


  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleClose = () => setShowLogoutModal(false);
  const handleShow = () => setShowLogoutModal(true);

  const signout1 = () => {
    handleShow();
  };

  const handleLogout = () => {
    handleClose();
    // Perform the logout logic here
    logout();
  };

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='user-logo' src={currentUser?.logo != null ? currentUser?.logo : toAbsoluteUrl('/media/logos/empty-user.jpg')} style={{
    borderRadius: '100px'
}}/>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {/* {currentUser?.first_name} {currentUser?.first_name} */}
            </div>
            <a href='/dashboard' className='fw-bold text-muted text-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a href={'/settings/account-settings'} className='menu-link px-5'>
          My Profile
        </a>
      </div>

      <div className='menu-item px-5'>
        <Link to={'/products/my-products'} className='menu-link px-5'>
          <span className='menu-text'>My Products</span>
          <span className='menu-badge'>
            {/* <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span> */}
          </span>
        </Link>
      </div>

      <div className='menu-item px-5'>
        <Link to={'/orders/all-orders'} className='menu-link px-5'>
          <span className='menu-text'>My Orders</span>
        </Link>
      </div>

      <div className='menu-item px-5'>
        <Link to={'/settings/activity'} className='menu-link px-5'>
          <span className='menu-text'>Activity</span>
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={signout1} className='menu-link px-5'>
          Logout
        </a>
      </div>


      <Modal show={showLogoutModal} onHide={handleClose} centered  dialogClassName ="w-325px">

  
<div className="modal-content" >

<div className="modal-body" style={{ textAlign: 'center' ,}}>
<img src={`${process.env.REACT_APP_IMAGE_API_URL}/portal_images/a_logout_3.jpg`} alt="Logout" style={{ width: '100px', height: '100px' }} />
<p><strong>Are you sure you want to logout?</strong></p>
</div>
<div className="modal-footer border-0 mt-n9" style={{justifyContent:"center"}}>

 <button type="button" className="btn btn-primary w-100px" onClick={handleLogout}>
   Ok
 </button>
 <button
   type="button"
   className="btn btn-danger w-100px"
   
   onClick={handleClose}
 >
   Cancel
 </button>
</div>
</div>

</Modal>
    </div>
  )
}

export { HeaderUserMenu }
