
import React from 'react'


type Props = {
  className: string
}

const ListsWidget1: React.FC<Props> = ({className}) => (
  <>
    <div
      id='kt_content_container'
      className='container-xxl pe-0 ol-xl-4 '
      style={{backgroundColor: '#ffffff'}}
    >
      <div className='row m-3 d-flex justify-content-center'>
        <div className='col-12 col-lg-5 bg-white mdborder boxborder' id='col1'>
          <div className='d-grid gap-2 col' style={{display: 'flex', justifyContent: 'center'}}>
            <p style={{marginTop: '5px'}} id='connectCheck' className='mt-6 fw-bolder text-center'>
              <button
                className='btn btn-primary btn-sm text-center'
                id='connectBtn'
                style={{borderRadius: '50px 50px 50px 50px', height: '35px'}}
              >
                CONNECT
              </button>
            </p>
            <div className='d-flex align-items-center justify-content-center' id='msgContainer'>
              <div className='bottom-0 mt-1 me-2 bg-warning rounded-circle h-10px w-10px statIndicator'></div>
              <p
                style={{marginTop: '5px'}}
                id='tooltipi'
                className='mt-6 fw-bolder'
                data-bs-toggle='tooltip'
                data-bs-placement='bottom'
                data-bs-html='true'
                title=''
                data-bs-original-title='<img alt="tooltip" src="assets/media/icons/duotune/general/gen044.svg" className="me-1"/>Please make sure device is connected'
              >
                DISCONNECTED
              </p>
            </div>
            <div
              className='btn-group'
              style={{marginTop: '15px'}}
              role='group'
              aria-label='Basic example'
            >
              <button
                type='button'
                className='btn btn-secondary btn-sm disableOnStart disableOnPlay'
              >
                -
              </button>
              <button
                type='button'
                className='btn  btn-sm'
                style={{background: '#6c757d', cursor: 'initial', opacity: 'initial'}}
                disabled
              >
                Top View
              </button>
              <button
                type='button'
                className='btn btn-secondary btn-sm disableOnStart disableOnPlay'
                style={{borderLeft: '1px solid #F2EBFE'}}
              >
                +
              </button>
            </div>

            <img
              src={`${process.env.REACT_APP_IMAGE_API_URL}/portal_images/images/Group8.png`}
              className='top-view'
              style={{marginTop: '30px', marginLeft: '35px'}}
              alt="grp-8"
            />

            <div
              className='btn-group'
              style={{marginTop: '30px'}}
              role='group'
              aria-label='Basic example'
            >
              <button type='button' className='btn btn-secondary btn-sm'>
                -
              </button>
              <button
                type='button'
                className='btn  btn-sm'
                style={{
                  background: '#6c757d',
                  cursor: 'initial',
                  opacity: 'initial',
                  borderLeft: '1px solid #F2EBFE',
                  color: 'white',
                }}
                disabled
              >
                Side View
              </button>
              <button
                type='button'
                className='btn btn-secondary btn-sm disableOnStart disableOnPlay'
                style={{borderLeft: '1px solid #F2EBFE'}}
              >
                +
              </button>
            </div>
            <img
              src={`${process.env.REACT_APP_IMAGE_API_URL}/portal_images/images/muggrp.png`}
              className='top-view pb-4'
              style={{marginTop: '40px'}}
              alt="grp-9"
            />
          </div>
        </div>
        <div
          className='col-12 col-lg-7 mt-4 mt-lg-0 p-0'
          style={{backgroundColor: '#ffff'}}
          id='col1'
        >
          <div className='container p-5'>
            <div className='row'>
              <div className='col-md-12 p-0 mb-3'>
                <div>
                  <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                    <li className='nav-item'>
                      <a
                        className='nav-link active fw-bold'
                        data-bs-toggle='tab'
                        href='#kt_tab_pane_1'
                      >
                        STANDARD
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                        BASIC
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_3'>
                        ADVANCED
                      </a>
                    </li>
                  </ul>
                  <div className='tab-content' id='myTabContent'>
                    <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
                      <form className='p-3 mb-auto p-2'>
                        <div
                          className='form-floating mb-3'
                          style={{borderBottom: '5px solid #4fc9da'}}
                        >
                          <select
                            className='form-select bg-lighten selectin'
                            aria-label='preset'
                            style={{borderRadius: '14px 14px 0px 0px'}}
                            name='sortBY'
                          >
                            <option selected value='A'>
                              Amazon
                            </option>
                            <option value='B'>Flipkart</option>
                            <option value='C'>Shopify</option>
                          </select>
                          <label
                            htmlFor='floatingSelect'
                            className='ms-3 font-weight-bold'
                            style={{color: '#4fc9da'}}
                          >
                            SELECT PRESET
                          </label>
                        </div>
                      </form>
                      <div className=' row d-flex justify-content-end  align-self-end pe-6'>
                        <button
                          type='button'
                          className='btn  btn-light btn-sm mw-25  m-2 disableOnStart'
                          style={{width: '80px'}}
                        >
                          Reset
                        </button>
                        <button
                          type='button'
                          className='btn btn-primary  btn-sm mw-25  m-2 disableOnStart'
                          style={{width: '80px'}}
                          id='play'
                        >
                          Capture
                        </button>
                      </div>
                    </div>
                    <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
                      <div className='row p-3 mb-2  photo-tool' style={{borderRadius: '8px'}}>
                        <div>
                          <h4 className='scan mt-2 mb-0 mt-3'>Photo</h4>
                          <div className='row'>
                            <p className='lead text-muted'>
                              Use the - / + buttons on the side panel to adjust the view based on
                              your preference and take a singular specialized shot.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='row d-flex justify-content-lg-end justify-content-center pe-6 align-items-end align-self-end'>
                        <button
                          type='button'
                          className='btn  btn-light btn-sm   m-2 disableOnStart'
                          style={{width: '80px'}}
                        >
                          Reset
                        </button>
                        <button
                          type='button'
                          className='btn btn-primary  btn-sm   m-2 disableOnStart'
                          style={{width: '120px'}}
                        >
                          Take Photo
                        </button>
                      </div>
                      <div className='row p-3 mb-2  photo-tool' style={{borderRadius: '8px'}}>
                        <div>
                          <h4 className='scan mt-2 mb-0 mt-6 pt-5'>Video</h4>
                          <div className='row'>
                            <p className='lead text-muted'>
                              Use the - / + buttons on the side panel to adjust the view based on
                              your preference and start recording the video. Press capture for full
                              360 degree rotation of the object.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='row d-flex justify-content-lg-end justify-content-center align-items-end align-self-end pe-6'>
                        <button
                          type='button'
                          className='btn  btn-light btn-sm   m-2 disableOnStart'
                          style={{width: '80px'}}
                        >
                          Reset
                        </button>
                        <button
                          type='button'
                          className='btn btn-primary  btn-sm   m-2 disableOnStart'
                          style={{width: '120px'}}
                        >
                          Take Video
                        </button>
                      </div>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='kt_tab_pane_3'
                      role='tabpanel'
                      aria-labelledby='nav-profile-tab'
                    >
                      <div id='advancedstart' className='d-none'>
                        <div className='row d-flex'>
                          <p className='d-flex justify-content-center'>
                            Click plus button to add additional features
                          </p>
                        </div>
                        <div className='col d-flex justify-content-center'>
                          <button className='mdc-button disableOnStart' id='add'>
                            <span className='material-icons mt-5 add'>add_circle_outline</span>
                          </button>
                        </div>
                      </div>
                      <div className='container inner-container' id='advancedbegin'>
                        <div className='row'>
                          <div className='col d-flex mt-3 justify-content-center mb-1'>
                            <button className='disableOnStart bg-white  border-0' id='stop1'>
                              <i
                                className='fa-regular fa-circle-stop'
                                style={{fontSize: '30px', color: 'black'}}
                              ></i>
                            </button>
                            <button className='bg-white disableOnStart border-0' id='playadvanced'>
                              <i
                                className='fa-solid fa-circle-play'
                                style={{fontSize: '30px', color: 'black'}}
                              ></i>
                            </button>
                            <button
                              className=' bg-white border-0'
                              id='pauseadvanced'
                              style={{display: 'none'}}
                              disabled
                            >
                              <i
                                className='fa-solid fa-circle-pause border-0'
                                style={{fontSize: '30px', color: 'black'}}
                              ></i>
                            </button>
                          </div>
                          <div className='indef' id='indef'>
                            <div className='subline inc' id='inc'></div>
                            <div className='subline dec' id='dec'></div>
                          </div>
                        </div>
                        <div
                          className='dropdown px-4'
                          style={{display: 'flex', flexDirection: 'column'}}
                        >
                          <div id='advancedholder'>
                            <div
                              className='dropdown px-4 deleteme'
                              id='repeat'
                              style={{marginTop: '40px'}}
                            >
                              <div
                                id='play-btn1'
                                className='row p-3 mb-2 bg-lighten'
                                style={{borderRadius: '8px'}}
                              >
                                <div className='d-flex justify-content-between'>
                                  <div className='scanningmode'>Scanning Mode</div>
                                  <div
                                    className=' hide disableOnPlay  btn btn-sm btn-icon btn-active-light-danger'
                                    id='kt_drawer_example_basic_close'
                                  >
                                    <span className='svg-icon svg-icon-2'>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                      >
                                        <rect
                                          opacity='0.5'
                                          x='6'
                                          y='17.3137'
                                          width='16'
                                          height='2'
                                          rx='1'
                                          transform='rotate(-45 6 17.3137)'
                                          fill='black'
                                        ></rect>
                                        <rect
                                          x='7.41422'
                                          y='6'
                                          width='16'
                                          height='2'
                                          rx='1'
                                          transform='rotate(45 7.41422 6)'
                                          fill='black'
                                        ></rect>
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                                <div className='row d-flex radio-content'>
                                  <form action='#' className='customRadio customCheckbox p-0'>
                                    <div className='row mb-0 ms-2'>
                                      <div className='row justify-content-start'>
                                        <div className='col-12 d-flex'>
                                          <div className='row d-flex justify-content-center'>
                                            <input
                                              type='radio'
                                              name='textEditor'
                                              id='dreamweaver0'
                                              checked
                                            />{' '}
                                            <label htmlFor='dreamweaver0'>Photo</label>
                                          </div>
                                          <div className='row d-flex justify-content-center'>
                                            <input type='radio' name='textEditor' id='sublime0' />
                                            <label htmlFor='sublime0'>Video</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                                <div>
                                  <p className='scan mt-2 mb-0'>Vertical Angle</p>
                                  <div className='row'>
                                    <div className='slidecontainer d-flex text-center'>
                                      <input
                                        className='col-10 angle-slider1'
                                        data-display='val0'
                                        type='range'
                                        min='0'
                                        value='0'
                                        max='90'
                                        step='10'
                                        id='myRange0'
                                      />
                                      <div className='col-2 range-box ms-2 p-2'>
                                        <span id='val0'>0</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <p className='mt-2 mb-0'>Number of Shots</p>
                                  <div className='row'>
                                    <div>
                                      <input
                                        type='number'
                                        className='form-control shots'
                                        placeholder='Enter Number'
                                        id='num0'
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='row pb-5'>
                            <div className='col d-flex justify-content-center'>
                              <button className='disableOnPlay border-0 bg-white' id='add'>
                                <i
                                  className='fa-solid fa-circle-plus'
                                  style={{fontSize: '30px', color: 'black'}}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export {ListsWidget1}

