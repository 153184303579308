// import axios from 'axios'
// import {useEffect, useRef, useState} from 'react'
// import { useState } from 'react'
import { capitalizeFirstLetter, convertDate } from '../../../../../app/utils/Helper'
type DataProp = {
  plugin: string
  orderid: string
  firstname: string
  lastname: string
  date: string
  // total: string
  status: string
}

export const WooOrderSales = (prop: DataProp) => {
  // const [image, setImage] = useState('')

  function setStatus(status: string) {
    if (status.toLowerCase() === 'completed') {
      return (
        <span className='badge badge-light-success fs-7 fw-bolder mb-2'>
          {capitalizeFirstLetter(prop.status) || ""}
        </span>
      )
    } else if (status.toLowerCase() === 'processing') {
      return (
        <span className='badge badge-light-primary fs-7 fw-bolder mb-2'>
          {capitalizeFirstLetter(prop.status) || ''}
        </span>
      )
    } else if (status.toLowerCase() === 'failed') {
      return (
        <span className='badge badge-light-danger fs-7 fw-bolder mb-2'>
          {capitalizeFirstLetter(prop.status) || ''}
        </span>
      )

    } else if (status.toLowerCase() === 'on-hold') {
      return (
        <span className='badge badge-light-warning fs-7 fw-bolder mb-2'>
          {capitalizeFirstLetter(prop.status) || ''}
        </span>
      )
    } else if (status.toLowerCase() === "pending") {
      return (
        <span className='badge badge-light-info fs-7 fw-bolder mb-2'>
          {capitalizeFirstLetter(prop.status) || ''}
        </span>
      )
    } else if (status.toLowerCase() === "cancelled") {
      return (
        <span className='badge badge-light fs-7 fw-bolder mb-2'>
          {capitalizeFirstLetter(prop.status) || ''}
        </span>
      )
    } else if (status.toLowerCase() === "refunded") {
      return (
        <span className='badge badge-light-dark fs-7 fw-bolder mb-2'>
          {capitalizeFirstLetter(prop.status) || ''}
        </span>
      )
    } else if (status.toLowerCase() === "fulfilled") {
      return (
        <span className='badge badge-light-dark fs-7 fw-bolder mb-2'>
          {capitalizeFirstLetter(prop.status) || ''}
        </span>
      )
    } else {
      return (
        <span className='badge badge-light-warning fs-7 fw-bolder mb-2'>
          {capitalizeFirstLetter("Un-fulfilled") || ''}
        </span>
      )
    }
  }
  var image = ''
  if (prop.plugin === "woocommerce") {
    image = (`${process.env.REACT_APP_IMAGE_API_URL}/portal_images/woo3.png`)
  } else if (prop.plugin === "shopify") {
    image = (`${process.env.REACT_APP_IMAGE_API_URL}/portal_images/shopi.png`)
  } else if (prop.plugin === "bigcommerce") {
    image = (`${process.env.REACT_APP_IMAGE_API_URL}/portal_images/bigcommerce_small_logo.png`)
  } else if (prop.plugin === 'flipkart') {
    image = `${process.env.REACT_APP_IMAGE_API_URL}/portal_images/flip.png`
  } else if (prop.plugin === 'amazon') {
    image = `${process.env.REACT_APP_IMAGE_API_URL}/portal_images/amazon.png`
  } else if (prop.plugin === "third_party") {
    image = (`${process.env.REACT_APP_IMAGE_API_URL}/portal_images/IGO.png`)
  } else if (prop.plugin === "ondc") {
    image = (`${process.env.REACT_APP_IMAGE_API_URL}/portal_images/ondc_logo.jpg`)
  }


  return (
    <>

      <tr
        className='orders-list'
      >
        <td className='text-center'>
          <div className='symbol symbol-50px'>
            <span className='symbol-label' style={{ backgroundColor: '#fff' }}>
              <img src={image} height='40px' width='40px' alt='alt-img' className='img-fluid' />
            </span>
          </div>
        </td>
        <td className='text-gray-700 fw-bolder  mb-1 fs-6 text-center'>
          {prop.orderid}
        </td>
        <td className='text-gray-700 fw-bolder  mb-1 fs-6 text-center'>
          {convertDate(prop.date)}
        </td>
        <td className='text-gray-700 fw-bolder  mb-1 fs-6 text-center'>
          {capitalizeFirstLetter(prop.firstname) + ' ' + capitalizeFirstLetter(prop.lastname)}
        </td>
      
        <td className='project-state order-state text-center' colSpan={2}>
          {setStatus(prop.status)}
        </td>
      </tr>
    </>
  )
}