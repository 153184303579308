
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Bars } from 'react-loader-spinner'

import { timedRefresh } from '../../../../app/utils/Helper'



type Props = {
    className: string
    items?: number
}

const AmazonCatalog: React.FC<Props> = ({ className, items = 6 }) => {

    const [totalProducts, setTotalProducts] = useState<any>([])

    const [category_1, setcat_1] = useState<any>([])
    const [selected_cat1, set_selected_cat1] = useState<any>('Grocery & Gourmet Foods')

    const [category_2, setcat_2] = useState<any>([])
    const [selected_cat2, set_selected_cat2] = useState<any>('Canned & Jarred Food')

    const [category_3, setcat_3] = useState<any>([])
    const [selecetd_cat3, set_selected_cat3] = useState<any>('Fruits')

    const [selected_sort, set_selected_sort] = useState<any>('Rating')

    const loading = useRef<HTMLDivElement | null>(null)
    const card = useRef<HTMLDivElement | null>(null)



    useEffect(() => {
        async function getTotalProd() {
            await axios

                .post(`${process.env.REACT_APP_MAIN_API_URL}/api/dashboard/get-scraped-category-1`, { headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') } })

                .then(function (response) {
                    var cat1_list: any = []
                    var value
                    response.data.data.forEach((data: any, i: any) => {
                        const value = data['Category_1'];
                        cat1_list.push(value);
                    });
                    setcat_1(cat1_list)
                    loading.current?.classList.remove('d-block')
                    loading.current?.classList.add('d-none')
                    card.current?.classList.remove('d-none')
                    card.current?.classList.add('d-block')
                })
                .catch(function (error: any) {
                    console.log(error.response.data)
                    if (error.response.data === 'Verification error.') {
                        timedRefresh()
                    }
                }

                )
        }


        getTotalProd()
    }, [])

    useEffect(() => {
        const formData = new FormData()
        formData.append('category_1', selected_cat1)
        axios
            .post(`${process.env.REACT_APP_MAIN_API_URL}/api/dashboard/get-scraped-category-2`, { 'category_1': selected_cat1 }, { headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') } })
            .then(function (response) {

                var cat2_list: any = []
                var value
                response.data.data.forEach((data: any, i: any) => {
                    value = data['Category_2'];
                    cat2_list.push(value);

                    
                });
                setcat_2(cat2_list)
            })
            .catch(function (error: any) {
                console.log(error.response.data)
                if (error.response.data === 'Verification error.') {
                    timedRefresh()
                }
            }
            )
    }, [selected_cat1])

    useEffect(() => {
        const formData = new FormData()
        formData.append('category_1', selected_cat1)
        formData.append('category_2', selected_cat2)
        axios
            .post(`${process.env.REACT_APP_MAIN_API_URL}/api/dashboard/get-scraped-category-3`, { 'category_1': selected_cat1, 'category_2': selected_cat2 }, { headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') } })
            .then(function (response) {

                var cat3_list: any = []
                var value
                response.data.data.map((data: any, i: any) => {
                    const value = data['Category_3'];
                    cat3_list.push(value);
                    return value; })
                setcat_3(cat3_list)
            })
    }, [selected_cat1, selected_cat2])

    useEffect(() => {
        if (selected_cat1 && selected_cat2 && selecetd_cat3 && selected_sort) {

            const formData = new FormData()
            formData.append('category_1', selected_cat1)
            formData.append('category_2', selected_cat2)
            formData.append('category_3', selecetd_cat3)
            formData.append('filter', selected_sort)
            axios
                .post(`${process.env.REACT_APP_MAIN_API_URL}/api/dashboard/get-scraped-data`, { 'category_1': selected_cat1, 'category_2': selected_cat2, 'category_3': selecetd_cat3, 'filter': selected_sort }, { headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') } })
                .then(function (response) {

                    setTotalProducts(response.data.data.slice(0, 5))
                })
        }


    }, [selected_sort, selected_cat1, selected_cat2, selecetd_cat3])



    return (
        <>
            <div ref={loading} className={`card d-block ${className}`} style={{ height: '475px' }}>


                <div className='d-flex justify-content-center align-items-center '
                    style={{
                        width: '-webkit-fill-available',
                        height: '-webkit-fill-available',
                        position: 'absolute'
                    }}>
                    <Bars
                        height="80"
                        width="80"
                        color="#C8CDCF"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            </div>
            <div ref={card} className='card card-xl-stretch h-475px d-none'>
                <div className='card-header pt-4 mb-7'>
                    <div className='card-title'>
                        <h2 className='m-0 mt-n2 fs-2'>Amazon Catalogue</h2>

                        <span className='mt-13 text-gray-400 fw-bold fs-6 text-start' style={{ marginLeft: "-14.5rem" }}>Product Recommendations </span>

                        {/* Main cat */}

                        <div className='d-flex align-items-center ms-auto' style={{ width: '20%' }}>
                            <select
                                onChange={(e) => { set_selected_cat1(e.target.value); set_selected_cat2(''); set_selected_cat3('') }}
                                className='form-select  me-4 '
                                value={selected_cat1}
                            >
                                <option value=''>Category</option>
                                {category_1.map((data: any, i: any) => {
                                    return (
                                        <>
                                            {<option value={data}>{data}</option>}
                                        </>
                                    )
                                })}
                            </select>
                        </div>

                        {/* sub category */}

                        <div className='d-flex align-items-center' style={{ width: '20%' }}>
                            <select
                                onChange={(e) => { set_selected_cat2(e.target.value); set_selected_cat3('') }}
                                className='form-select  me-4 '
                                value={selected_cat2}
                            >
                                <option value=''>Sub Category</option>
                                {category_2.map((data: any, i: any) => {
                                    return (
                                        <>
                                            {<option value={data}>{data}</option>}
                                        </>
                                    )
                                })}
                            </select>
                        </div>

                        {/*sub sub category*/}

                        <div className='d-flex align-items-center' style={{ width: '20%' }}>
                            <select
                                onChange={(e) => { set_selected_cat3(e.target.value) }}
                                className='form-select  me-4'
                                value={selecetd_cat3}
                            >
                                <option value=''>Sub Sub Category</option>
                                {category_3.map((data: any, i: any) => {
                                    return (
                                        <>
                                            {<option value={data}>{data}</option>}
                                        </>
                                    )
                                })}
                            </select>
                        </div>

                        {/* sort based on */}

                        <div className='d-flex align-items-center' style={{ width: '15%' }}>
                            <select
                                onChange={(e) => { set_selected_sort(e.target.value) }}
                                className='form-select  me-4'
                            >

                                <option value=''>Sort By</option>
                                <option value='Rating'>Rating</option>
                                <option value='Ranking'>Ranking</option>
                                <option value='Recent_Order'>Recent Orders</option>

                            </select>
                        </div>
                    </div>


                    <div className='d-flex card-toolbar'>

                    </div>

                </div>

                <div className='card-body pt-0 mb-xl-9 mt-9'>

                    <div>
                        {totalProducts.map((data: any, i: number) => {
                            return (
                                <div key={i} className='row justify-content-between mb-6'>
                                    <div className='col-10 d-flex'>
                                        <div className='g-0 col-auto'>
                                            <img
                                                src={data.IMAGE_1}
                                                className='me-3 col-3'
                                                style={{ width: '40px', borderRadius: '4px' }}
                                                alt='alt-logo'
                                            />
                                        </div>
                                        <div className='g-0 ps-1 col-10'>
                                            <div className='text-dark fw-bolder  fs-6 text-truncate mt-6'>{data.Product_Name}</div>

                                        </div>
                                    </div>

                                    <div className='col-2 d-flex'>

                                        <div className='g-0 ps-1 col-10'>
                                            <div className='text-dark fw-bolder  fs-6 text-truncate mt-6'>{`Rank - ${i + 1}`}</div>

                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export { AmazonCatalog }
