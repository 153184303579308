/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  TablesWidget10,
  TablesWidget12,
  MixedWidget9,
  ChartsWidget3,
  MixedWidget10,
} from '../../../_metronic/partials/widgets'
import { useRecoilState } from 'recoil'
import { allStatiAtom } from '../../modules/Atom'
import { AmazonCatalog } from '../../../_metronic/partials/widgets/lists/AmazonCatalog'
const DashboardPage: FC<any> = ({ profcontent }) => (
  <>
    <div className='row g-5 gx-xxl-8'>
      <div className='col-12'>
        <ListsWidget2 />
      </div>
    </div>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-5'>
        <MixedWidget9 className='card-xl-stretch mb-xl-8' chartColor='danger' chartHeight={'200px'} statistics={profcontent} />
      </div>

      <div className='col-xl-7'>
        <ChartsWidget3 className='card-xxl-stretch-50 mb-5 mb-xl-8' />
      </div>
    </div>




    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
    <div className='col-xl-8'>
        <TablesWidget12 className='card-xxl-stretch mb-xl-3' />
      </div>

      <div className='col-xl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
     
    </div>
    {/* end::Row */}

    {/* begin::Row */}

    <div className='row g-5 gx-xxl-12'>
      <div className='col-xl-12'>
        <MixedWidget10 className='card-xxl-stretch mb-xl-3' chartColor='primary' chartHeight={'300px'} />
      </div>
      {/* <div className='col-xl-4'>
        <TablesWidget1 className='card-xxl-stretch mb-xl-3' />
      </div> */}
    </div>
    <div className='row gy-5 g-xl-8'>
    <div className='col-xl-8'>
        <AmazonCatalog className='card-xl-stretch mb-xl-8' />
      </div>
      {/* <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div> */}

      <div className='col-xl-4'>
        <TablesWidget10 className='card-xxl-stretch mb-xl-3' />
      </div>
    </div>

   

    {/* end::Row */}
  </>
)

const DashboardWrapper: FC = () => {
  const [profcontent, setProfcontent] = useRecoilState(allStatiAtom)

  if (profcontent.total_revenue === null) {
    setProfcontent({ ...profcontent, total_revenue: '0' })
  }

  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage profcontent={profcontent} />
    </>
  )
}

export { DashboardWrapper }