/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../helpers'

const HeaderNotificationsMenu: FC = () => (
  <div
    className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
    data-kt-menu='true'
  >
    <div
      className='d-flex flex-column bgi-no-repeat rounded-top'
      style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/image_1.jpeg')}')`}}
    >
      <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
      Contact Us 
      </h3>
      <div className='text-center px-4'>
            <img
              className='mw-37 mh-150px'
              alt='metronic'
              src={toAbsoluteUrl('/media/illustrations/sketchy-1/contact_us.jpg')}
              style={{borderRadius: '33px'}}
            />
          </div>

  
    </div>

    <div className='tab-content mt-n4'>
   

      <div className='tab-pane fade show active' id='kt_topbar_notifications_2' role='tabpanel'>
        <div className='d-flex flex-column px-9'>
          <div className='pt-10 pb-0'>
            <h3 className='text-dark text-center fs-6 fw-bolder'>Support</h3>

            <div className='text-center text-gray-600 fw-bold pt-1'>
            info@isgoing.online
            </div>

            {/* <div className='text-center mt-5 mb-9'>
              <a
                href='#'
                className='btn btn-sm btn-primary px-6'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_upgrade_plan'
              >
                Upgrade
              </a>
            </div> */}
          </div>
          <div className='pt-4 pb-0'>
            <h3 className='text-dark text-center fs-6 fw-bolder'>Customer Service</h3>

           

        

<div className='text-center text-gray-600 fw-bold pt-1'>
India   <span className='fs-8 opacity-75 ps-3'>+91 90849 70849</span>
</div>
<div className='text-center text-gray-600 fw-bold pt-1'>
            US   <span className='fs-8 opacity-75 ps-3'>+1 (408) 639-5058</span>
            </div>

          
          </div>
          <div className='mt-9'></div>

        
        </div>
      </div>

      
    </div>
  </div>
)

export {HeaderNotificationsMenu}
