import {FC, useEffect, useRef, useState} from 'react'
import './ViewStyle.css'
import axios from 'axios'
import { timedRefresh } from '../../utils/Helper'
import { useLocation } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import { useRecoilValue } from 'recoil';
import { currencyAtom } from '../Atom';


const View_catalogue: FC = () => {
    const location = useLocation();
    const state = location.state;
    const loading = useRef<any>()
    const card = useRef<any>()
    const [products, setProducts] = useState<any>([])
    const currency = useRecoilValue(currencyAtom)
    useEffect(() => {    
        axios
          .post(`${process.env.REACT_APP_MAIN_API_URL}/api/products/get-catalogue-product-details`,{prodid :state} ,{
            headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') },
          })
          .then(async function (response) {

          
            loading.current?.classList.add('d-none')
      card.current?.classList.remove('d-none')
            
            var data = await response.data.data[0]
            if (data) {
                setProducts(data)
            }
          })
          .catch(function (error: any) {
            console.log(error.response.data)
            if(error.response.data ==='Verification error.'){
    
              timedRefresh()
            }
          })
      }, [state])
  return (


    <>  
     <div ref={loading} className='card' style={{ height: '100%', width: '100%' }}>
        <div className='d-flex justify-content-center align-items-center '
          style={{
            width: '-webkit-fill-available',
            height: '-webkit-fill-available',
            position: 'absolute'
          }}>
          <Bars
            height="50"
            width="50"
            color="#C8CDCF"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      </div>
    
    <div ref={card} className='card d-none' style={{ height: '100%', width: '100%' }}
    
  >
      <main className="container_1">


<div className="left-column ">
  <img data-image="black" className="active" src={products.img_1} alt=""style={{marginLeft: '-9rem'}}
  
/>
 
</div>



<div className="right-column" style={{marginLeft: '-5rem'}}>

 
  <div className="product-description">
    <span>{products.cat_1}</span>
    <h1 className='mt-1'>{products.name}</h1>
    <p className='mt-1'>{products.description}</p>
  </div>

 
  <div className="product-configuration">




   
    <div className="product-info-stock-sku">
      <div className="stock instock">
          <span className="label-available me-2" style = {{display: 'inline-block',
            color: '#5cb85c',
            marginBottom: '15px'}}>Availability:</span >{(products.stock_status)}</div>
  </div>
   
  </div>

  
  <div className="product-price" style={{marginTop: -'9rem'}}>
    <span>{currency}{products.price}</span>
    
  </div>
</div>
</main>
</div>
    </>
  )
}

export {View_catalogue}
