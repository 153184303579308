// Topbar
import clsx from 'clsx'
import React, { FC } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu } from '../../../partials'
import { useAuth } from '../../../../app/modules/auth'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'
  

const Topbar: FC = () => {
  // const {config} = useLayout();
  const { currentUser } = useAuth();

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>

<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
    <a
      href='/settings/activity'
      className='h-25px d-flex align-items-center' // Added 'd-flex align-items-center' class
       style={{borderRadius:"13px", backgroundColor:"#d3d3d38c"}}
   >
      <div className='d-flex align-items-center'>
        <img
          src='/media/icons/duotune/art/star.png' alt='no_img'
          className='svg-icon-2 h-25px ms-2'
        />
        <span className='ml-2 text-gray me-2 ms-2' style={{color:"#808080bd", fontWeight:"bold"}}>{currentUser?.credits}</span>
        {/* Added 'ml-2 text-gray' classes */}
      </div>
    </a>
  </div>

      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Menu- wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
         
         <KTSVG
            path='/media/icons/duotune/art/art017.svg'
            className='svg-icon-2'
          />
        </div>
        <HeaderNotificationsMenu />
        {/* end::Menu wrapper */}
      </div>
      {/* Activities */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Drawer toggle */}

        <a
          href='/settings/activity'
          className='btn btn-icon btn-active-light-primary btn-custom'
        >
          <KTSVG
             path='/media/icons/duotune/art/art018.svg'
            className='svg-icon-2'
          />
        </a>
        {/* <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          id='kt_activities_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen032.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div> */}
        {/* end::Drawer toggle */}
      </div>

      {/* NOTIFICATIONS */}

      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Drawer toggle */}

        <a
          href='/orders/all-orders'
          className='btn btn-icon btn-active-light-primary btn-custom'
        >
          <KTSVG
            path='/media/icons/duotune/art/art012.svg'
            className='svg-icon-2'
          />
        </a>
        {/* <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          id='kt_activities_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/ecommerce/ecm001.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div> */}
        {/* end::Drawer toggle */}
      </div>


      {/* CHAT */}



      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={currentUser?.logo != null ? currentUser?.logo : toAbsoluteUrl('/media/logos/empty-user.jpg')} alt='user-logo' style={{
    borderRadius: '100px'
}}/>
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {/* {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )} */}
    </div>
  )
}

export { Topbar }
