import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { capitalizeFirstLetter, timedRefresh } from '../../../../app/utils/Helper';
import { Bars } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

type Props = {
  className: string;
};

const ListsWidget3: React.FC<Props> = ({ className }) => {
  const [popcontent, setPopcontent] = useState<any[]>([]); // Fix: Initialize popcontent as an empty array
  const loading = useRef<HTMLDivElement | null>(null);
  const card = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_MAIN_API_URL}/api/dashboard/get-popular-products`, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') },
        });
        const data = response.data;


        if ('message' in data) {
          loading.current?.classList.remove('d-flex');
          loading.current?.classList.add('d-none');
          card.current?.classList.remove('d-none');
          card.current?.classList.add('d-block');
        } else {
          loading.current?.classList.remove('d-flex');
          loading.current?.classList.add('d-none');
          card.current?.classList.remove('d-none');
          card.current?.classList.add('d-block');
          setPopcontent(data.slice(0, 6));
        }
      } catch (error: any) {
        if (error.response.data === 'Verification error.') {
          timedRefresh();
        }
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <div className={`card ${className} h-550px`}>
        <div className='card card-xl-stretch'>
          <h2 className='px-9 pt-9 pb-5 fs-2'>My Popular Products</h2>
          <span className='mt-n5 text-gray-400 fw-bold fs-6 ms-9'>Recent Popular Products</span>

          <div className='card-body pt-0'>
            <div className='d-flex flex-stack mt-3 mb-7'></div>

            <div
              ref={loading}
              className='d-flex justify-content-center align-items-center'
              style={{
                width: '-webkit-fill-available',
                height: '-webkit-fill-available',
                position: 'absolute',
              }}
            >
              <Bars
                height='80'
                width='80'
                color='#C8CDCF'
                ariaLabel='bars-loading'
                wrapperStyle={{}}
                wrapperClass=''
                visible={true}
              />
            </div>

            {popcontent.length === 0 ? (
              // Fix: Check for an empty popcontent array
              <div
                ref={card}
                className='d-none'
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  className='card border-0 pt-3'
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '200px',
                  }}
                >
                  <>
                    <div className='text-dark fw-bolder fs-3 text-truncate'>No products</div>
                    <div className='text-gray fw-bold fs-6 text-truncate'>
                      Connect plugin{' '}
                      <Link to={'/plugins/platform'} className='text-primary-100 fw-bold' style={{ color: '#009ef7ab' }}>
                        here
                      </Link>
                    </div>
                  </>
                </div>
              </div>
            ) : (
              <div ref={card} className='d-none'>
                {popcontent.map((data: any, i: number) => (
                  <div key={i} className='d-flex justify-content-between mb-8'>
                    <div className='w-lg-300px d-flex align-items-center me-3'>
                      <Row>
                        <Col className='col-2'>

                          <div className='symbol symbol-40px'>
                            <span className='symbol-label' style={{ backgroundColor: '#fff' }}>
                              <img src={data.image_url || '/media/logos/empty_image.jpg'} alt='woo-img' className='img-fluid' style={{ height: "4rem" }} />
                            </span>
                          </div>

                        </Col>
                        <Col className='col-7'>
                          <div className='w-lg-150px'>
                            <div className='text-dark fw-bolder fs-6 text-truncate'>
                              {capitalizeFirstLetter(data.name)}
                            </div>
                            <span className='text-gray-400 fw-bold d-block pt-1'>Orders - {data.count}</span>
                          </div>
                        </Col>
                        <Col className='col-3'>
                          <div className='w-lg-150px'>
                            <div className='text-dark fw-bolder fs-6 text-truncate ms-10'>{`Rank-${i + 1}`}</div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { ListsWidget3 };
