
import { Link } from "react-router-dom";
import { capitalizeFirstLetter, ddmmFormat } from "../../../../../app/utils/Helper";


type DataProp = {
    date: string
    data: string
    color: string
    link: string
    change_type:string
    platform: string
    prodsku_orderid: string
    type:string

}

export const ActivityNew = (prop: DataProp) => {

 
    return (
        <div className='timeline-item'>
        <div className='timeline-label fw-bolder text-gray-800 fs-8'>{ddmmFormat(prop.date)}</div>
        <div className='timeline-badge'>
            <i className={`fa fa-genderless ${prop.color} fs-1`}></i>
        </div>
        <div className='timeline-content d-flex'>
            {prop.platform === "woocommerce" && prop.type === "product" && (prop.change_type === "updation" || prop.change_type === "creation") ? (
                <Link to={'/products/woo-update/' + prop.prodsku_orderid} state={prop.prodsku_orderid} className='timeline-content fw-bold primary text-hover-primary text-gray-800 ps-3 fs-7'
                >
                    {capitalizeFirstLetter(prop.data)}
                </Link>
            ) : prop.platform === "shopify" && prop.type === "product" && (prop.change_type === "updation" || prop.change_type === "creation") ? (
                <Link to={'/products/shopi-update/' + prop.prodsku_orderid} state={prop.prodsku_orderid} className='timeline-content fw-bold text-hover-primary text-gray-800 ps-3 fs-7'>
                    {capitalizeFirstLetter(prop.data)}
                </Link>
            ) : prop.platform === "bigcommerce" && prop.type === "product" && (prop.change_type === "updation" || prop.change_type === "creation") ? (
                <Link to={'/products/big-update/' + prop.prodsku_orderid} state={prop.prodsku_orderid} className='timeline-content fw-bold text-hover-primary text-gray-800 ps-3 fs-7'>
                    {capitalizeFirstLetter(prop.data)}
                </Link>
            ) : prop.platform === "amazon" && prop.type === "product" && (prop.change_type === "updation" || prop.change_type === "creation") ? (
                <Link to={'/products/amazon-update/' + prop.prodsku_orderid} state={prop.prodsku_orderid} className='timeline-content fw-bold text-hover-primary text-gray-800 ps-3 fs-7'>
                    {capitalizeFirstLetter(prop.data)}
                </Link>
            ) : prop.platform === "woocommerce" && prop.type === "order" ? (
                <Link to={'/products/amazon-update/' + prop.prodsku_orderid} state={prop.prodsku_orderid} className='timeline-content fw-bold text-hover-primary text-gray-800 ps-3 fs-7'>
                    {capitalizeFirstLetter(prop.data)}
                </Link>
            ) : prop.platform === "shopify" && prop.type === "order" ? (
                <Link to={'/products/amazon-update/' + prop.prodsku_orderid} state={prop.prodsku_orderid} className='timeline-content fw-bold text-hover-primary text-gray-800 ps-3 fs-7'>
                    {capitalizeFirstLetter(prop.data)}
                </Link>
            ) : prop.platform === "bigcommerce" && prop.type === "order" ? (
                <Link to={'/products/amazon-update/' + prop.prodsku_orderid} state={prop.prodsku_orderid} className='timeline-content fw-bold text-hover-primary text-gray-800 ps-3 fs-7'>
                    {capitalizeFirstLetter(prop.data)}
                </Link>
            ) : prop.platform === "amazon" && prop.type === "order" ? (
                <Link to={'/products/amazon-update/' + prop.prodsku_orderid} state={prop.prodsku_orderid} className='timeline-content fw-bold text-hover-primary text-gray-800 ps-3 fs-7'>
                    {capitalizeFirstLetter(prop.data)}
                </Link>
            ) : prop.platform === "flipkart" && prop.type === "order" ? (
                <Link to={'/products/amazon-update/' + prop.prodsku_orderid} state={prop.prodsku_orderid} className='timeline-content fw-bold text-hover-primary text-gray-800 ps-3 fs-7'>
                    {capitalizeFirstLetter(prop.data)}
                </Link>
            ) : prop.platform === null && prop.type === null ? (
                <a className='timeline-content fw-bold text-gray-800 ps-3 fs-7'>
                    {capitalizeFirstLetter(prop.data)}
                </a>
            ) : (
                <a className='timeline-content fw-bold text-gray-800 ps-3 fs-7'>
                    {capitalizeFirstLetter(prop.data)}
                </a>
            )}
        </div>
    </div>
    
    )
}