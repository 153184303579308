
import {Route,  Routes} from 'react-router-dom'
import { View_catalogue } from './View_catalogue'
import Proview_page_woo from './Proview_page_woo'
import Proview_page_shopi from './Proview_page_shopi'
import Proview_page_big from './Proview_page_big'


const ViewPage = () => (
  <Routes>
   
      <Route path='view-catalogue/:sku' element={<View_catalogue />} />
      <Route path='proview_page_big/:sku' element={<Proview_page_big />} />
      <Route path='proview_page_shopi/:sku' element={<Proview_page_shopi />} />
      <Route path='proview_page_woo/:sku' element={<Proview_page_woo />} />
      
  
  </Routes>
)

export {ViewPage}
