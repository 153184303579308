import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { getUserByToken, login } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { LoginProp } from '../core/_models';
import { Col } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

export function Login() {
  const [loading, setLoading] = useState(false);
  const [loginUser, setLoginUser] = useState<LoginProp>({ email: "", password: "" });
  const { saveAuth, setCurrentUser } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => { return () => { setLoginUser({ email: "", password: "" }) } }, []);

  const handleChange = (e: any) => { setLoginUser({ ...loginUser, [e.target.name]: e.target.value }) };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    // TODO: login validation has to be done
    setLoading(true);
    try {
      if (loginUser.email === "" || loginUser.password === "") { alert("Please provide proper credentials."); return; }
      const { data: auth } = await login(loginUser.email, loginUser.password);
      saveAuth(auth);
      const { data: user } = await getUserByToken(auth.api_token);
      setCurrentUser(user);
    } catch (error: any) {
      saveAuth(undefined)
      setCurrentUser(undefined)
  
      if (error.response.status === 400) {
        alert("Incorrect Password.")
      } else if (error.response.status === 401) {
        alert(error.response.data.message)
      } else if (error.response.status === 500) {
        alert("Please reload and try again.")
      }
    } finally {
      setLoading(false);
    }
  }
  // function myFunction() {
  //   var x: any = document.getElementById("myInput");
  //   if (x.type === "password") {
  //     x.type = "text";
  //   } else {
  //     x.type = "password";
  //   }
  // }

  return (

    <>
      <div className='row w-lg-750px bg-white shadow-sm  mx-auto' style={{ borderRadius: "4px" }}>


        <Col className='col-6 text-center' style={{ backgroundColor: "#fcd42c", borderBottomLeftRadius: "4px", borderTopLeftRadius: "4px" }}>

          <img
            src={toAbsoluteUrl(`${process.env.REACT_APP_IMAGE_API_URL}/portal_images/IGO.png`)}
            style={{ marginTop: "4rem", width: "80%" }}
            alt='big_logo'
            className='symbol-label img-fluid '
          />
        </Col>
        <Col className='col-6 p-10'>
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>Sign In </h1>
          </div>
          <form
            className='form w-100'
            onSubmit={handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
 


            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
              <input
                placeholder='Email'
        
                className={clsx('form-control form-control-lg form-control-solid')}
                type='email'
                name='email'
                autoComplete='off'
                value={loginUser.email}
                onChange={handleChange} />
            </div>
        

      
            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
    
                  <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>

           
                </div>

              </div>
              <input
                placeholder='Password'
                type={showPassword ? 'text' : 'password'}
                autoComplete='off'
                name='password'
                value={loginUser.password}
                onChange={handleChange}
                className={clsx('form-control form-control-lg form-control-solid ')}
                id="myInput" />
              <span className="btn btn-sm btn-icon position-absolute translate-middle " style={{ marginLeft: "300px", position: "absolute", marginTop: "-23px" }}

                onClick={togglePasswordVisibility}
              >
                {showPassword ? <label ><i className=' fa fa-thin fa-eye'
                ></i></label> : <i className=' fa fa-thin fa-eye-slash'
                ></i>}
              </span>



            </div>

            <div className='text-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
              >
                {!loading && <span className='indicator-label'>Sign In</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>

              <div className='text-gray-400 fw-bold fs-4'>
                New Here?{' '}
                <Link to='/auth/registration' className='link-primary fw-bolder'>
                  Create an Account
                </Link>
              </div>
              <br>
              </br>
              <Link
                to='/auth/forgot-password'
                className='link-primary mb-9 fs-6 fw-bolder'
                style={{ marginLeft: '5px' }}
              >
                Forgot Password ?
              </Link>
   
            </div>
  
          </form>
        </Col>



      </div>

    </>
  )
}
