/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
// import { KTSVG } from '../../../helpers'
// import {ChatInner} from '../../chat/ChatInner'

const DrawerMessenger: FC = () => {
  return <></>
}

export { DrawerMessenger }
