import axios from 'axios'
import React, {useEffect, useState} from 'react'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import { Link } from 'react-router-dom'
import { timedRefresh } from '../../../../app/utils/Helper'
import { ActivityNew } from './content/ActivityNew'


type Props = {
  className: string
}

const TablesWidget10: React.FC<Props> = ({className}) => {
  const [activity, setActivity] = useState<any>([])
  // get activity
  useEffect(() => {
    async function getCatalogueProd() {
      await axios
        .post(`${process.env.REACT_APP_MAIN_API_URL}/api/user/get-activity-data`, {
          offset: "0"
        }, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') }
        })
        .then(function (response) {

        
          
          setActivity(response.data.data)
          
          

        })
        .catch(function (error: any) {
          console.log(error.response.data)
          if(error.response.data === 'Verification error.'){
  
            timedRefresh()
          }
        }
        
        )
    }
    getCatalogueProd()
    return () => {
      setActivity([])
    }
  }, ["0"])

  let colours = ["text-primary", "text-warning", "text-success", "text-danger", "text-secondary","text-primary", "text-warning", "text-success", "text-danger", "text-secondary"];


  return (
    <>
     <div className={`card h-475px ${className}`}>
  <Row>
    <Col>
      <h3 className='px-8  d-flex align-items-start flex-column mt-3'>
        <br />
        <span className='mb-2 fw-bolder'>Activities</span>
        <span className='  text-gray-400 fw-bold fs-6 ms-1'>Recent Activities</span>
      </h3>
    </Col>
    <Col className='text-end me-11'>
      <Link to='/settings/activity' className='btn btn-sm btn-primary mt-11'>
        Activity
      </Link>
    </Col>
  </Row>

  <br />


  {activity.length === 0 ? (
    <div
      className='card-body pt-0'
      style={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        className='card border-0 pt-3'
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '180px',
        }}
      >
        <>
          <div className='text-dark fw-bolder fs-6 text-truncate'>No Activities</div>
        </>
      </div>
    </div>
  ) : (
    <div className='card-body pt-0' style={{ maxHeight: '350px', overflowY: 'auto' }}>
      <div className='d-flex flex-stack mt-3 mb-7'>
        <div className='timeline-label'>
          {activity.slice(0, 9).map((data: any, i: any) => {
            return <ActivityNew key={data.id} color={colours[i]} date={data.created} data={data.description} link={data.link} platform={data.platform} change_type={data.change_type} prodsku_orderid={data.prodsku_orderid} type={data.type}/>;
          })}
        </div>
      </div>
    </div>
  )}
</div>

    </>
  )
}

export {TablesWidget10}