import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'




const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProductsPage = lazy(() => import('../modules/products/ProductsPage'))
  const PluginsPage = lazy(() => import('../modules/plugins/PluginsPage'))
  const OrdersPage = lazy(() => import('../modules/orders/OrdersPage'))
  const StudioPage = lazy(() => import('../modules/studio/StudioPage'))
  const SettingsPage = lazy(() => import('../modules/settings/SettingsPage'))
  const AIpage = lazy(() => import('../modules/ai_components/AIPage'))
  const AIwebpage = lazy(() => import('../modules/ai_website/AIwebpage'))
  const ImageToolPage = lazy(() => import('../modules/image_tools/ImageToolPage'))
  const ReportPage = lazy(() => import('../modules/reports/ReportPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='products/*'
          element={
            <SuspensedView>
              <ProductsPage />
            </SuspensedView>
          }
        />
        <Route
          path='plugins/*'
          element={
            <SuspensedView>
              <PluginsPage />
            </SuspensedView>
          }
        />
        <Route
          path='studio/*'
          element={
            <SuspensedView>
              <StudioPage />
            </SuspensedView>
          }
        />
        <Route
          path='orders/*'
          element={
            <SuspensedView>
              <OrdersPage />
            </SuspensedView>
          }
        />

<Route
          path='reports/*'
          element={
            <SuspensedView>
              <ReportPage />
            </SuspensedView>
          }
        />


<Route
          path='image_tools/*'
          element={
            <SuspensedView>
              <ImageToolPage />
            </SuspensedView>
          }
        />

        <Route
          path='Ai-Tools/*'
          element={
            <SuspensedView>
              <AIpage />
            </SuspensedView>
          }
        />

        <Route
          path='AIwebpage/*'
          element={
            <SuspensedView>
              <AIwebpage />
            </SuspensedView>
          }
        />

        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />

        {/* <Route
          path='Website_with_AI/*'
          element={
            <SuspensedView>
              <AIpage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
