import React, { useEffect, useState } from 'react';


import './Proview.css'
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import axios from 'axios';
import { timedRefresh } from '../../utils/Helper';
import { useLocation } from 'react-router-dom';

const Proview_page_woo = () => {

  const location = useLocation();
  const state = location.state;


  const [productDetails, setProductDetails] = useState<any>({
    productid: '',
    pluginname: 'woocommerce',
    productname: '',
    productdescription: '',
    weight: '',
    img_1: '',
    img_2: '',
    img_3: '',
    img_4: '',
    img_5: '',
    sku: state,
    brand: '',
    cat_1: '',
    cat_2: '',
    length: '',
    breadth: '',
    height: '',
    cgst: '',
    sgst: '',
    igst: '',
    hse_eac: '',
    template_rendering: '',
    woo_json: '',
    bigcommerce_json: '',
    shopify_json: '',
    amazon: '',
    bigcommerce: '',
    flipkart: '',
    igo_lite: '',
    ondc: '',
    shopify: '',
    woocommerce: ''

  })

  useEffect(() => {
    var isMounted = true;
    async function firstApi() {
      axios.post(`${process.env.REACT_APP_MAIN_API_URL}/api/user/get-all-products-page-product-details`, { sku: productDetails.sku }, { headers: { "Authorization": 'Bearer ' + localStorage.getItem('api_token') } })
        .then(function (response: any) {
          var data = response.data.data[0];
          if (isMounted) {
            if (data) {

              setProductDetails({
                productname: data.name,
                productdescription: data.description,
                weight: data.weight,

                pluginname: 'woocommerce',
                img_1: data.img_1 || '',
                img_2: data.img_2 || '',
                img_3: data.img_3 || '',
                img_4: data.img_4 || '',
                img_5: data.img_5 || '',
                sku: data.sku,
                brand: data.brand,
                cat_1: data.cat_1,
                cat_2: data.cat_2,
                length: data.length,
                breadth: data.breadth,
                height: data.height,
                cgst: data.cgst,
                sgst: data.sgst,
                igst: data.igst,

                template_rendering: data.template_rendering,
                woo_json: data.woo_json,
                bigcommerce_json: data.bigcommerce_json,
                shopify_json: data.shopify_json,
                amazon: data.amazon,
                bigcommerce: data.bigcommerce,
                flipkart: data.flipkart,
                igo_lite: data.igo_lite,
                ondc: data.ondc,
                shopify: data.shopify,
                woocommerce: data.woocommerce,

              })







              return data.sku
            }
          }
        })
        .catch(function (error: any) {
          console.log(error.response.data)
          if (error.response.data === 'Verification error.') {

            timedRefresh()
          }
        }

        )



    }

    firstApi()

    return () => { isMounted = false }


  }, [productDetails.sku]);

  return (
    <div className="container">
      <div className="heading-section">

      </div>
      <div className="row">
        <div className="col-5">
          <div id="slider" className="owl-carousel product-slider">
            <div className="item" style={{ textAlign: "center" }}>
              <img src={productDetails.img_1} alt="" style={{ height: "457px", borderRadius: "5px" }} />
            </div>


            <Row className='row-cols-md-4 mt-15' style={{ textAlign: "center", justifyContent: "center" }}>


              <Col>

                {productDetails.img_2 === "" ? '' : <div className="item">
                  <img src={productDetails.img_2} alt="" style={{ width: "120px", height: "120px", borderRadius: "5px" }} />
                </div>}



              </Col>
              <Col>

                {productDetails.img_3 === "" ? '' : <div className="item">
                  <img src={productDetails.img_3} alt="" style={{ width: "120px", height: "120px", borderRadius: "5px" }} />
                </div>}



              </Col>
              <Col>

                {productDetails.img_4 === "" ? '' : <div className="item">
                  <img src={productDetails.img_4} alt="" style={{ width: "120px", height: "120px", borderRadius: "5px" }} />
                </div>}



              </Col>
              <Col>

                {productDetails.img_5 === "" ? '' : <div className="item">
                  <img src={productDetails.img_5} alt="" style={{ width: "120px", height: "120px", borderRadius: "5px" }} />
                </div>}



              </Col>





            </Row>






          </div>

        </div>
        <div className="col-md-6">
          <div className="product-dtl">
            <div className="product-info">
              <div className="product-name">{productDetails.productname}</div>
              <div className="reviews-counter mt-5">
                <div className="rate">
                  <input type="radio" id="star5" name="rate" value="5" checked />
                  <label htmlFor="star5" title="text">5 stars</label>
                  <input type="radio" id="star4" name="rate" value="4" checked />
                  <label htmlFor="star4" title="text">4 stars</label>
                  <input type="radio" id="star3" name="rate" value="3" checked />
                  <label htmlFor="star3" title="text">3 stars</label>
                  <input type="radio" id="star2" name="rate" value="2" />
                  <label htmlFor="star2" title="text">2 stars</label>
                  <input type="radio" id="star1" name="rate" value="1" />
                  <label htmlFor="star1" title="text">1 star</label>
                </div>
                <span>3 Reviews</span>
              </div>
              <div className="product-price-discount"><span>{productDetails.woo_json.woocommerce_salePrice}</span><span className="line-through">{productDetails.woo_json.woocommerce_price}</span></div>
            </div>
            <p>{productDetails.cat_1} {productDetails.cat_2}</p>
            <div className="row">
              <div className="col-form-label-sm">
                <label htmlFor="size">Brand</label>
                <h3>{productDetails.brand || "Nil"}</h3>
              </div>
              <div className="col-md-6">
                <label htmlFor="color">SKU</label>
                <h3>{productDetails.sku}</h3>
              </div>
            </div>
            <div className="product-count">
              <label htmlFor="size">Quantity : {productDetails.woo_json.woocommerce_stock || "Nil"}</label>

            </div>
          </div>


          <div className="product-info-tabs">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="description-tab" data-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true">Description</a>
              </li>

            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                {productDetails.productdescription}
              </div>

            </div>
          </div>
        </div>
      </div>


    </div>

  );
};

export default Proview_page_woo;
