/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
// import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {usePageData} from '../../core'
const Toolbar1: FC = () => {
  const {classes} = useLayout()
  // console.log(pageTitle)
  return (
    <>
      <div className='toolbar' id='kt_toolbar'>
       
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        >
          <DefaultTitle />

          {/* {pageTitle == 'Global Catalogue' ? (
            <div className='d-flex'>
              <div className='d-flex align-items-center'>
                <div className='d-flex flex-row align-items-center'>
                  <div className='d-flex flex-column mb-0'>
                    
                    <i
                      className='material-icons-outlined mb-3 ms-2'
                      id='kt_drawer_chat_toggle'
                      style={{cursor: 'pointer', fontSize: '24px'}}
                    >
                      add_business
                    </i>
                  </div>
                </div>
              </div>
            </div>
          ) : null} */}
        </div>
        {/* end::Container */}
      </div>
    </>
  )
}

export {Toolbar1}