var monthNames = ["Jan", "Feb", "Mar", "Apr", "May","Jun","Jul", "Aug", "Sep", "Oct", "Nov","Dec"];


var monthNames2 = ["1", "2", "3", "4", "5","6","7", "8", "9", "10", "11","12"];

export function convertDate(dateStr: string) {
    const date = new Date(dateStr);
    return date.getDate() + "-" + monthNames[date.getMonth()] + "-" + date.getFullYear();
}


export function convertDate2(dateStr: string) {
    const date = new Date(dateStr);

     
    return date.getFullYear() + "-" + monthNames2[date.getMonth()] + "-" + (date.getDate() < 10 ? '0' : '') + date.getDate();
}

// var monthNames = ["0","1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

export function ddmmFormat(dateStr: string) {
    const date = new Date(dateStr);
    return date.getDate() + "-" + monthNames[date.getMonth()] ;
}

export function ddmmyyFormat(dateStr: string) {
    const date = new Date(dateStr);
    return date.getDate() + "-" + monthNames[date.getMonth()] + "-" + date.getFullYear();
}

 export function capitalizeFirstLetter(str:string) {

    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
}

export function timedRefresh(){
    setTimeout("location.reload(true);");

  }