/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTSVG } from '../../../helpers'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
// import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import axios from 'axios'
import { Bars } from 'react-loader-spinner'
import { timedRefresh } from '../../../../app/utils/Helper'
import { useRecoilValue } from 'recoil'
import { currencyAtom } from '../../../../app/modules/Atom'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  statistics: any
}

const MixedWidget9: React.FC<Props> = ({ className, chartColor, chartHeight, statistics }) => {

  const chartRef = useRef<HTMLDivElement | null>(null)
  const loading1 = useRef<HTMLDivElement | null>(null)
  const card1 = useRef<HTMLDivElement | null>(null)

  const [graphMonth, setGraphMonth] = useState([])
  const [graphMonthData, setGraphMonthData] = useState([])

  const [graphWeek, setGraphWeek] = useState([])
  const [graphweekData, setGraphWeekData] = useState([])

  const [graphHorizondal, setGraphHorizondal] = useState([])
  const [graphVertical, setGraphVertical] = useState([])

  const [graphMonthrev, setGraphMonthrev] = useState([])
  const [graphMonthDatarev, setGraphMonthDatarev] = useState([])

  const [graphWeekrev, setGraphWeekrev] = useState([])
  const [graphweekDatarev, setGraphWeekDatarev] = useState([])

  const [graphHorizondalrev, setGraphHorizondalrev] = useState<any>([])
  const [graphVerticalrev, setGraphVerticalrev] = useState([])

  const currency = useRecoilValue(currencyAtom)
  
  if (statistics.total_revenue !== '' && graphMonth.length !== 0 && graphWeek.length !== 0 && graphMonthrev.length !== 0 && graphWeekrev.length !== 0 ) {
    loading1.current?.classList.add('d-none')
    card1.current?.classList.remove('d-none')
  }


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_MAIN_API_URL}/api/dashboard/get-weekly-order-count-graph-data`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') },
    })
      .then(async function (response) {
        var data = await response.data

        if (data) {
          var data1: any = (Object.keys(data));
          setGraphWeek(data1)
          var data2: any = (Object.values(data));
          setGraphWeekData(data2)
        }
      })
      .catch(function (error: any) {
        console.log(error.response.data)
        if(error.response.data ==='Verification error.'){

          timedRefresh()
        }
      }
      
      )

    axios.get(`${process.env.REACT_APP_MAIN_API_URL}/api/dashboard/get-monthly-order-count-graph-data`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') },
    })
      .then(async function (response) {
        var data = await response.data

        if (data) {
          var data1: any = (Object.keys(data));
          setGraphHorizondal(data1)
          setGraphMonth(data1)
          

          var data2: any = (Object.values(data));
          setGraphVertical(data2)
          setGraphMonthData(data2)

        }
      })
      .catch(function (error: any) {
        console.log(error.response.data)
        if(error.response.data === 'Verification error.'){

          timedRefresh()
        }
      }
      
      )

  }, [])

  useEffect(() => {

    axios.get(`${process.env.REACT_APP_MAIN_API_URL}/api/dashboard/get-weekly-order-revenue-graph-data`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') },
    })
      .then(async function (response) {
        var data = await response.data
     
        
        if (data) {
          var data1: any = (Object.keys(data));
       
          setGraphWeekrev(data1)

         
          

          var data2: any = (Object.values(data));

          const roundedNumbers = data2.map((num: number) =>{ 
            num = Math.round(num)
           
            return num
          });
      
          setGraphWeekDatarev(roundedNumbers)
        }
      })
      .catch(function (error: any) {
        console.log(error.response.data)
        if(error.response.data =='Verification error.'){

          timedRefresh()
        }
      }
      
      )

    axios.get(`${process.env.REACT_APP_MAIN_API_URL}/api/dashboard/get-monthly-order-revenue-graph-data`, {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('api_token') },
    })
      .then(async function (response) {
        var data = await response.data
      
    
        if (data) {

          var data1: any = (Object.keys(data));
       
          setGraphHorizondalrev(data1)
          setGraphMonthrev(data1)

          var data2: any = (Object.values(data));
          const roundedNumbers = data2.map((num: number) =>{ 
            num = Math.round(num)
      
            return num
          });
     
          setGraphVerticalrev(roundedNumbers)
          setGraphMonthDatarev(roundedNumbers)
        }
      })
      .catch(function (error: any) {
        console.log(error.response.data)
        if(error.response.data =='Verification error.'){

          timedRefresh()
        }
      }
      
      )

  }, [])


  useEffect(() => {
    if (!chartRef.current) {
      return;
    }
  const height: any = Math.max(...graphVertical);
  
    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartColor, height, graphHorizondal, graphVertical, graphHorizondalrev, graphVerticalrev, currency)
    );
  if (chart) {
      chart.render();
    }
   return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, graphHorizondal, graphVertical, graphHorizondalrev, graphVerticalrev, chartColor, currency]);

  const [storeActive, setStoreActive] = useState('month')

  const handleChange = (e: any) => {
    setStoreActive(e.currentTarget.id)
  

    if (e.currentTarget.id === 'month') {
   
     
      setGraphHorizondal(graphMonth)
      setGraphVertical(graphMonthData)
      setGraphHorizondalrev(graphMonthrev)
      setGraphVerticalrev(graphMonthDatarev)
    } else {
   
      setGraphHorizondalrev(graphWeekrev)
      setGraphVerticalrev(graphweekDatarev)
      setGraphHorizondal(graphWeek)
      setGraphVertical(graphweekData)
    }
  }

  return (
    <>
      <div ref={loading1} className={`card d-block ${className}`} style={{ height: '523px' }}>


        <div className='d-flex justify-content-center align-items-center '
          style={{
            width: '-webkit-fill-available',
            height: '-webkit-fill-available',
            position: 'absolute'
          }}>
          <Bars
            height="80"
            width="80"
            color="#C8CDCF"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
        {/* end::Body */}

      </div>

      <div ref={card1} className={`card d-none ${className}`}>

        {/* begin::Beader */}

        <div className='card-header border-0 py-5'>
          <h3 className='d-flex align-items-start flex-column'>
            <span className='mb-1 mt-4 fw-bolder fs-2'>Store Statistics</span>
            <span className='mt-1 text-gray-400 fw-bold fs-6'>Recent Store Statistics</span>
          </h3>


          <div className='card-toolbar'>
            <ul className='nav'>
              <li className='nav-item'>
                <a
                  className={'nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 sales-toggle ' + (storeActive === 'month' ? 'active' : '')}
                  id='month'
                  onClick={handleChange}
                >
                  Month
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={'nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 sales-toggle ' + (storeActive === 'week' ? 'active' : '')}
                  id='week'
                  onClick={handleChange}
                >
                  Week
                </a>
              </li>

            </ul>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body p-0 d-flex flex-column'>
          {/* begin::Stats */}
          <div className='card-px  flex-grow-1'>
            {/* begin::Row */}
            <div className='row g-0 mt-5 mb-10'>
              {/* begin::Col */}
              <div className='col'>
                <div className='d-flex align-items-center me-2'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-50px me-3'>
                    <div className='symbol-label bg-light-info'>
                      <KTSVG
                        path='/media/icons/duotune/ecommerce/ecm005.svg'
                        className='svg-icon-1 svg-icon-info'
                      />
                    </div>
                  </div>
                  {/* end::Symbol */}

                  {/* begin::Title */}
                  <div>
                    <div className='fs-4 text-dark fw-bolder'>
                      {statistics.total_orders || 0}
                    </div>
                    <div className='fs-7 text-muted fw-bold'> Total Orders</div>
                  </div>
                  {/* end::Title */}
                </div>
              </div>
              {/* end::Col */}

              {/* begin::Col */}
              <div className='col'>
                <div className='d-flex align-items-center me-2'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-50px me-3'>
                    <div className='symbol-label bg-light-danger'>
                      <KTSVG
                        path='/media/icons/duotune/finance/fin011.svg'
                        className='svg-icon-3x  svg-icon-danger'
                      />
                    </div>
                  </div>
                  {/* end::Symbol */}

                  {/* begin::Title */}
                  <div>
                    <div className='fs-4 text-dark fw-bolder'>{currency}{(parseFloat(statistics.total_revenue)).toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0}</div>
                    <div className='fs-7 text-muted fw-bold'>Total Revenue</div>
                  </div>
                  {/* end::Title */}
                </div>
              </div>
              {/* end::Col */}
            </div>
            {/* end::Row */}

            {/* begin::Row */}
            <div className='row g-0'>
              {/* begin::Col */}
              <div className='col'>
                <div className='d-flex align-items-center me-2'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-50px me-3'>
                    <div className='symbol-label bg-light-success'>
                      <KTSVG
                        path='/media/icons/duotune/ecommerce/ecm001.svg'
                        className='svg-icon-1 svg-icon-success'
                      />
                    </div>
                  </div>
                  {/* end::Symbol */}

                  {/* begin::Title */}
                  <div>
                    <div className='fs-4 text-dark fw-bolder'>
                      {statistics.open_orders || 0}
                    </div>
                    <div className='fs-7 text-muted fw-bold'>Open Order</div>
                  </div>
                  {/* end::Title */}
                </div>
              </div>
              {/* end::Col */}

              {/* begin::Col */}
              <div className='col'>
                <div className='d-flex align-items-center me-2'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-50px me-3'>
                    <div className='symbol-label bg-light-primary'>
                      <KTSVG
                        path='/media/icons/duotune/new/complete.svg'
                        className='svg-icon-1 svg-icon-primary'
                      />
                    </div>
                  </div>
                  {/* end::Symbol */}

                  {/* begin::Title */}
                  <div>
                    <div className='fs-4 text-dark fw-bolder'>
                      {statistics.completed_orders || 0}
                    </div>
                    <div className='fs-7 text-muted fw-bold'>Complete Order</div>
                  </div>
                  {/* end::Title */}
                </div>
              </div>
              {/* end::Col */}
            </div>
            {/* end::Row */}
          </div>
          {/* end::Stats */}

          {/* begin::Chart */}
          <div ref={chartRef} className='kt_charts_widget_3_chart card-rounded-bottom'></div>
          {/* end::Chart */}
        </div>

        {/* end::Body */}
      </div>

    </>
  )
}

const chartOptions = (chartColor: string, chartHeight: number, monthsData: string[], graphData: number[], graphHorizondalrev: string, graphVerticalrev: number[], currency:any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-danger')
  const lightColor = getCSSVariableValue('--bs-light-danger')

  
  const color3 = getCSSVariableValue('--bs-primary')
  const color3Light = getCSSVariableValue('--bs-light-primary')


  return {

    series: [

      {
        name: 'Revenue data',
        data: graphVerticalrev,
        
      },
      {
        name: 'Orders data',
        data: graphData,

      },
  
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 260,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor,color3],
    },
    xaxis: {
      categories: graphHorizondalrev,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: [
      {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      {
        opposite: true, // Display the second y-axis on the right side
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
    ],
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: [
        {
          formatter: function (val) {
            return `${currency} ` + val;
          },
        },
        {
          formatter: function (val) {
            return '' + val + ' orders';
          },
        },
      ],
    },
    colors: ["transparent","transparent"],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      colors: [lightColor,color3Light],
      strokeColors: [baseColor,color3],
      strokeWidth: 3,
    },
  }
}


export { MixedWidget9 }